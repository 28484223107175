export default function PrivacyPolicy() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">
          Legal
        </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Privacy Policy
        </h1>
        <div className="mt-10 max-w-2xl">
          <p>
            ** Results not typical or guaranteed. Past performance is not
            indicative of future returns, and financial investing is inherently
            risky. All content is provided subject to the qualifications and
            limitations set forth in our Terms of Service and Use.
          </p>
          <p className="mt-8">
            This information is for informational purposes only, as
            EquityTraderX.com is not registered as a securities broker-dealer or
            an investment adviser. No information provided is intended as
            securities brokerage, investment, tax, accounting or legal advice,
            as an offer or solicitation of an offer to sell or buy, or as an
            endorsement, recommendation, or sponsorship of any company,
            security, or fund.
          </p>
          <p className="mt-8">
            EquityTraderX.com cannot and does not assess, verify, or guarantee
            the adequacy, accuracy, or completeness of any information, the
            suitability or profitability of any particular investment, or the
            potential value of any investment or informational source.
          </p>
          <p className="mt-8">
            The reader bears responsibility for his/her own investment research
            and decisions and should seek the advice of a qualified securities
            professional before making any investment, and investigate and fully
            understand any and all risks before investing.
          </p>
          <p className="mt-8">
            EquityTraderX.com in no way warrants the solvency, financial
            condition, or investment advisability of any of the securities
            mentioned in communications or websites. In addition,
            EquityTraderX.com accepts no liability whatsoever for any direct or
            consequential loss arising from any use of this information. This
            information is not intended to be used as the sole basis of any
            investment decision, nor should it be construed as advice designed
            to meet the investment needs of any particular investor. Past
            performance is not necessarily indicative of future returns.
          </p>
        </div>
      </div>
    </div>
  );
}
