import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { StockMarket } from "react-ts-tradingview-widgets";

export default function Hero() {
  return (
    <div className="bg-white">
      <div className="relative isolate">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          />
        </svg>
        <div className="mx-auto max-w-7xl px-6 pt-14 pb-0 lg:py-24 lg:flex lg:items-center lg:gap-x-10 lg:px-8 relative">
          <div className="mx-auto max-w-xl lg:mx-0 lg:w-5/12">
            <div className="flex">
              <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <span className="font-semibold text-indigo-600">
                  Featured Stocks
                </span>
                <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                <a
                  href="/login?next=top-stocks"
                  className="flex items-center gap-x-1"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  See top picks
                  <ChevronRightIcon
                    className="-mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Master Pro Trading On ETX all-in-one Platform
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 md:pr-24">
              Embark on a journey to trading excellence with our Premium Stock
              Insights. Gain exclusive access to expert analysis, real-time
              updates, and a community of ambitious traders. Elevate your
              strategy and turn potential into profits.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="/signup?next=subscribe&tier_duration=annually"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Subscribe Now
              </a>
              <a
                href="/signup"
                className="text-sm font-semibold leading-6 text-gray-900 hover:underline hover:text-gray-600"
              >
                Join Free <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          {/* normal */}
          <div className="hidden lg:block w-full lg:w-7/12">
            <img
              alt="laptop showing ETX"
              src="/marketing/centered-lappy-sm.png"
              className="hidden lg:block relative lg:absolute lg:top-14 lg:-right-80 h-auto object-cover lg:mt-0 mt-14 lg:w-auto w-full"
              style={{ maxWidth: "1000px", height: "auto" }}
            />
          </div>
          <div className="hidden lg:block relative lg:absolute lg:bottom-0 lg:right-96 border-4 border-indigo-600 rounded-lg lg:mt-0 mt-8">
            <StockMarket
              colorTheme="dark"
              height={330}
              width="100%"
            ></StockMarket>
          </div>
          {/* mobile */}
          <div className="block lg:hidden relative w-full">
            <img
              alt="laptop showing ETX"
              src="/marketing/centered-lappy-sm.png"
              className="block lg:hidden relative h-auto object-cover mt-14 w-full"
              style={{ maxWidth: "1000px", height: "auto" }}
            />
            <div
              className="block lg:hidden absolute -bottom-12 border-4 border-indigo-600 rounded-lg mt-8 stock-widget-container"
              style={{ width: "50%" }}
            >
              <StockMarket
                colorTheme="dark"
                height={320}
                width="145%"
              ></StockMarket>
            </div>
            <div className="block lg:hidden" style={{ height: "100px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
