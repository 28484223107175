import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "./../components/Loading";
import BlogPostGrid from "./../components/BlogPostGrid";
import { useBlogPosts } from "./../components/UseBlogPosts";

export default function CategoryPage() {
  const { authorId } = useParams();
  const filterCondition = (item) =>
    item.fields.blogPostAuthor.fields.authorId === authorId;
  const { posts, isLoading, authorName, authorRole } =
    useBlogPosts(filterCondition);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {authorName}
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">{authorRole}</p>
        </div>
        <BlogPostGrid posts={posts} />
      </div>
    </div>
  );
}
