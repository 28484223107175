const featuredTestimonial = {
  body: "Joining this platform has revolutionized the way I trade. The insights and alerts have consistently guided me to profitable opportunities I would've otherwise missed.",
  author: {
    name: "Dalton Cruz",
    handle: "daltoncruz91",
    imageUrl: "/testimonials/man-6.jpg",
    logoUrl: "/logo/black_2_smaller.png",
  },
};

const testimonials = [
  [
    [
      {
        body: "The daily SMS alerts are a game-changer for my busy schedule. I've made more informed decisions and better trades than ever before.",
        author: {
          name: "Gregory Cochran",
          handle: "gregorycochran__75",
          imageUrl: "/testimonials/man-1.jpg",
        },
      },
      {
        body: "I never realized the impact of being part of a trading community until now. The support and insights have helped me grow exponentially.",
        author: {
          name: "James Conley",
          handle: "jamesconley",
          imageUrl: "/testimonials/man-2.jpg",
        },
      },
      {
        body: "The Premium Plan's in-depth analyses and webinars have taken my trading strategy to a whole new level. Worth every penny!",
        author: {
          name: "Kara Hudson",
          handle: "karahudson_56",
          imageUrl: "/testimonials/woman-1.jpg",
        },
      },
    ],
    [
      {
        body: "Access to expert-driven webinars and real-time market analysis has given me an edge in my trading. It's like having a mentor by my side.",
        author: {
          name: "Summer Stafford",
          handle: "summerstafford_",
          imageUrl: "/testimonials/woman-2.jpg",
        },
      },
      {
        body: "Their platform is not just a service; it's an investment in your trading future. The accuracy of their stock picks is unmatched.",
        author: {
          name: "Ruben Schultz",
          handle: "rubenschultz",
          imageUrl: "/testimonials/man-3.jpg",
        },
      },
    ],
  ],
  [
    [
      {
        body: "The daily debriefings have become an essential part of my day. They summarize exactly what I need to know about the market trends.",
        author: {
          name: "Michael Williams",
          handle: "michaelwilliams491",
          imageUrl: "/testimonials/man-4.jpg",
        },
      },
      {
        body: "Seeing my portfolio grow has been incredible. The alerts and analysis are timely, accurate, and profitable.",
        author: {
          name: "Tyler Smith",
          handle: "tylersmith_212",
          imageUrl: "/testimonials/man-5.jpg",
        },
      },
    ],
    [
      {
        body: "I was skeptical at first, but the consistent success of the stock picks made me a believer. This platform is a must for any serious trader.",
        author: {
          name: "Jose Tucker",
          handle: "josetucker40",
          imageUrl: "/testimonials/main-man.jpg",
        },
      },
      {
        body: "The premium community is full of experienced traders who are always willing to help. It's more than a platform; it's a partnership for success.",
        author: {
          name: "Caitlin Hernandez",
          handle: "caitlinhernandez__45",
          imageUrl: "/testimonials/woman-3.jpg",
        },
      },
      {
        body: "Thanks to the platform's consistent reliability, I never miss out on key trading opportunities. It's dependable, enlightening, and utterly vital to my strategy.",
        author: {
          name: "Janet Oliver",
          handle: "janetoliver",
          imageUrl: "/testimonials/woman-4.jpg",
        },
      },
    ],
  ],
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Testimonials() {
  return (
    <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
      <div
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
            Testimonials
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We have worked with thousands of amazing traders
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <img
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
                src={featuredTestimonial.author.imageUrl}
                alt=""
              />
              <div className="flex-auto">
                <div className="font-semibold">
                  {featuredTestimonial.author.name}
                </div>
                <div className="text-gray-600">{`@${featuredTestimonial.author.handle}`}</div>
              </div>
              <img
                className="h-10 w-auto flex-none"
                src={featuredTestimonial.author.logoUrl}
                alt=""
              />
            </figcaption>
          </figure>
          {testimonials.map((columnGroup, columnGroupIdx) => (
            <div
              key={columnGroupIdx}
              className="space-y-8 xl:contents xl:space-y-0"
            >
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={classNames(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 &&
                        columnIdx === columnGroup.length - 1)
                      ? "xl:row-span-2"
                      : "xl:row-start-1",
                    "space-y-8"
                  )}
                >
                  {column.map((testimonial) => (
                    <figure
                      key={testimonial.author.handle}
                      className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                    >
                      <blockquote className="text-gray-900">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <img
                          className="h-10 w-10 rounded-full bg-gray-50"
                          src={testimonial.author.imageUrl}
                          alt=""
                        />
                        <div>
                          <div className="font-semibold">
                            {testimonial.author.name}
                          </div>
                          <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                        </div>
                      </figcaption>
                    </figure>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
