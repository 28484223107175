import React, { useState, useEffect } from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { TickerTape } from "react-ts-tradingview-widgets";
import Loading from "./components/Loading";
import { useParams } from "react-router-dom";
import NewsGridItem from "./components/NewsGridItem";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { getTitleToSlug } from "./components/utils";

function getSmallImageUrl(item) {
  const smallImage = item.image.find((item) => item.size === "small");

  return smallImage ? smallImage.url : "/default-news.png";
}

export default function NewsAuthor({ backgroundColor }) {
  const [newsFromAuthor, setNewsFromAuthor] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [authorName, setAuthorName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const bgColorClass = backgroundColor || "bg-white";
  const { authorId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const itemsPerPage = 39;

  const getCurrentPageFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return parseInt(queryParams.get("page")) || 1;
  };

  async function fetchNews(page) {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/api/news?page=${page}&limit=${itemsPerPage}&authorId=${encodeURIComponent(
          authorId
        )}`
      );
      const data = await response.json();

      if (data && data.length > 0) {
        setAuthorName(data[0].author);
      }

      const totalPages = Math.ceil(data.length / itemsPerPage);
      setTotalPages(totalPages);
      setIsLoading(false);
      setNewsFromAuthor(data);
    } catch (error) {
      console.error("Error fetching news from author:", error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const currentPage = getCurrentPageFromUrl();
    fetchNews(currentPage);
  }, [location]);

  const handlePageClick = (event) => {
    navigate(`/news?page=${event.selected + 1}`);
  };

  if (isLoading) {
    return <Loading center={true} />;
  }

  return (
    <div className={`${bgColorClass}`}>
      <main className="isolate">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
          <div className="mx-auto max-w-2xl text-center mb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {authorName}
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Latest news articles from {authorName}
            </p>
          </div>
          <TickerTape
            symbols={[
              {
                proName: "FOREXCOM:SPXUSD",
                title: "S&P 500",
              },
              {
                proName: "FOREXCOM:NSXUSD",
                title: "Nasdaq 100",
              },
              {
                proName: "NASDAQ:TSLA",
                title: "Tesla",
              },
              {
                proName: "NASDAQ:META",
                title: "Meta",
              },
              {
                proName: "NASDAQ:NVDA",
                title: "Nvidia",
              },
              {
                proName: "BLACKBULL:US30",
                title: "Dow Jones",
              },
              {
                proName: "FX_IDC:EURUSD",
                title: "EUR/USD",
              },
              {
                proName: "BITSTAMP:BTCUSD",
                title: "BTC/USD",
              },
            ]}
            colorTheme="light"
          ></TickerTape>
          <div className="grid gap-6 lg:grid-cols-4">
            {newsFromAuthor.map((item, index) => (
              <NewsGridItem
                key={index}
                imageUrl={
                  item.isETXPost
                    ? item.smallImage.fields.file.url
                    : getSmallImageUrl(item)
                }
                link={`/news/${item.id}/${getTitleToSlug(item.title)}`}
                title={item.title}
                source={item.author}
                date={new Date(
                  item.isETXPost ? item.postDate : item.created
                ).toLocaleDateString()}
                isBig={
                  index === 0 || index === 15 || index === 26 || index === 41
                }
              />
            ))}
          </div>
          <ReactPaginate
            previousLabel={
              <div className="flex items-center">
                <ArrowLongLeftIcon
                  className="mr-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Previous
              </div>
            }
            nextLabel={
              <div className="flex items-center">
                Next
                <ArrowLongRightIcon
                  className="ml-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            }
            pageCount={totalPages}
            onPageChange={handlePageClick}
            containerClassName={
              "pt-12 flex items-center justify-center border-t border-gray-200 px-4 sm:px-0"
            }
            pageLinkClassName={
              "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }
            breakLabel={"..."}
            breakClassName={
              "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
            }
            activeLinkClassName={"underline border-indigo-500 text-indigo-600"}
            previousLinkClassName={
              "mr-4 inline-flex items-center border-t-2 border-transparent pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }
            nextLinkClassName={
              "ml-4 inline-flex items-center border-t-2 border-transparent pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }
            disabledClassName={"hidden"}
            forcePage={getCurrentPageFromUrl() - 1}
          />
        </div>
      </main>
    </div>
  );
}
