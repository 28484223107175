export const toSlug = (str) => {
  return str.toLowerCase().replace(/\s+/g, "-");
};

export function formatDate(dateString) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
}

export function formatDateReadable(dateString) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: true,
  };
  return new Date(dateString).toLocaleString("en-US", options);
}

export function getTitleToSlug(title) {
  return title
    .toLowerCase() // Convert to lowercase
    .replace(/&/g, "and") // Replace '&' with 'and'
    .replace(/[\s\W-]+/g, "-") // Replace spaces, non-word characters, and dashes with a single dash
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing dashes
}
