import React, { useState, useEffect } from "react";
import { TickerTape } from "react-ts-tradingview-widgets";
import Loading from "./components/Loading";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import NotFoundPage from "./NotFoundPage";
import RichTextRenderer from "./components/RichTextRenderer";

function formatDate(date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()} ${hours}:${formattedMinutes} ${ampm}`;
}

function getLargeImageUrl(item) {
  const largeImage = item.find((item) => item.size === "large");

  return largeImage ? largeImage.url : null;
}

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export default function NewsPost({ backgroundColor }) {
  const [newsPost, setNewsPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const bgColorClass = backgroundColor || "bg-white";
  const { newsId } = useParams();

  useEffect(() => {
    async function fetchNewsPost() {
      try {
        const response = await fetch("/api/news?newsId=" + newsId);
        const data = await response.json();

        setIsLoading(false);
        if (data.length > 0) {
          setNewsPost(data[0]);
        } else {
          setNewsPost(data);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }
    fetchNewsPost();
  }, [newsId]);

  useEffect(() => {
    // Function to load the Twitter script
    const loadTwitterScript = () => {
      if (!window.twttr) {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        script.charset = "utf-8";
        document.body.appendChild(script);
      } else {
        // If script already loaded, reinitialize the widgets
        window.twttr.widgets.load();
      }
    };

    loadTwitterScript();
  }, [newsPost]);

  // avoid xss attacks
  let cleanHtml =
    newsPost && !newsPost.isETXPost ? DOMPurify.sanitize(newsPost.body) : "";

  if (newsPost && newsPost.message === "News post not found") {
    return <NotFoundPage />;
  }

  return isLoading ? (
    <div className={`${bgColorClass}`}>
      <main className="isolate">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center mb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Latest Financial News
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Stay ahead with swift updates and insights on pivotal financial
              trends.
            </p>
          </div>
          <Loading center={false} />
        </div>
      </main>
    </div>
  ) : (
    <div className={`${bgColorClass}`}>
      <main className="isolate">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
          <div className="mx-auto max-w-2xl text-left mb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {newsPost && newsPost.isETXPost
                ? newsPost.title
                : decodeHtml(newsPost.title)}
            </h2>
            <div className="relative flex items-center gap-x-4 my-4">
              <div className="text-sm leading-6">
                <p className="font-semibold text-indigo-500 hover:text-indigo-700 cursor-pointer">
                  <a
                    href={`/news/author/${encodeURIComponent(newsPost.author)}`}
                  >
                    {newsPost.author}
                  </a>
                </p>
                <p className="text-gray-600">
                  {formatDate(
                    new Date(
                      newsPost && newsPost.isETXPost
                        ? newsPost.postDate
                        : newsPost.created
                    )
                  )}
                </p>
              </div>
            </div>
          </div>
          <TickerTape
            symbols={[
              {
                proName: "FOREXCOM:SPXUSD",
                title: "S&P 500",
              },
              {
                proName: "FOREXCOM:NSXUSD",
                title: "Nasdaq 100",
              },
              {
                proName: "NASDAQ:TSLA",
                title: "Tesla",
              },
              {
                proName: "NASDAQ:META",
                title: "Meta",
              },
              {
                proName: "NASDAQ:NVDA",
                title: "Nvidia",
              },
              {
                proName: "BLACKBULL:US30",
                title: "Dow Jones",
              },
              {
                proName: "FX_IDC:EURUSD",
                title: "EUR/USD",
              },
              {
                proName: "BITSTAMP:BTCUSD",
                title: "BTC/USD",
              },
            ]}
            colorTheme="light"
          ></TickerTape>
          <div>
            {newsPost && newsPost.image && newsPost.image.length > 0 && (
              <div className="mx-auto max-w-4xl text-left mb-8 flex items-center justify-center">
                <img
                  alt="article header"
                  className="rounded-md shadow-lg"
                  src={
                    newsPost && newsPost.isETXPost
                      ? newsPost.image
                      : getLargeImageUrl(newsPost.image)
                  }
                />
              </div>
            )}
            {newsPost && newsPost.isETXPost ? (
              <RichTextRenderer richText={newsPost.body} />
            ) : (
              <div
                className="news-content mx-auto max-w-5xl"
                dangerouslySetInnerHTML={{ __html: cleanHtml }}
              />
            )}

            {newsPost && newsPost.isETXPost ? (
              <div className="news-content mt-4">
                <a
                  href={`/news/author/${encodeURIComponent(newsPost.author)}`}
                  target="_blank"
                  className="italic mx-auto max-w-5xl text-indigo-500 hover:text-indigo-600"
                  rel="noreferrer"
                >
                  <i>Source: Equity Trader X</i>
                </a>
              </div>
            ) : (
              <div className="news-content mx-auto max-w-5xl mt-4">
                <a
                  href={newsPost.url}
                  target="_blank"
                  className="italic mx-auto max-w-5xl text-indigo-500 hover:text-indigo-600"
                  rel="noreferrer"
                >
                  <i>Source: Benzinga</i>
                </a>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
