function truncateWithEllipses(text, max) {
  return text.length > max ? text.substring(0, max) + "..." : text;
}

export default function NewsGridItem({
  imageUrl,
  link,
  title,
  source,
  date,
  isBig,
}) {
  const baseClasses =
    "zoom relative overflow-hidden rounded-lg shadow-lg dark:shadow-black/20";
  const sizeClasses = isBig
    ? "lg:col-span-2 lg:row-span-2 min-h-[300px]"
    : "min-h-[150px]";
  const finalClasses = `${baseClasses} ${sizeClasses}`;

  return (
    <div className={finalClasses}>
      <img
        src={imageUrl}
        alt="News Grid Item"
        className="w-full h-full object-cover align-middle transition duration-300 ease-linear"
      />
      <a href={link}>
        <div className="absolute inset-0 h-full w-full overflow-hidden bg-[hsla(0,0%,0%,0.5)] bg-fixed">
          <div className="flex h-full items-end justify-start">
            <div className="m-6 text-white">
              <h5 className="mb-3 text-md font-bold drop-shadow-md">
                {truncateWithEllipses(title, isBig ? 150 : 50)}
              </h5>
              <p>
                <small className="drop-shadow-md text-xs">
                  Published <u>{date}</u> by {source}
                </small>
              </p>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 h-full w-full overflow-hidden bg-fixed transition duration-300 ease-in-out hover:bg-[hsla(0,0%,99%,0.15)]"></div>
      </a>
    </div>
  );
}
