import { Navigate } from "react-router-dom";
import { useUser } from "./../UserContext";

export default function PremiumRoute({ element: Element, ...rest }) {
  const { state } = useUser();
  const user = state.user;

  return user.subscriptionStatus === "active" ? (
    Element
  ) : (
    <Navigate to="/not-premium" replace />
  );
}
