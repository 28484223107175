export default function TermsOfService() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">
          Legal
        </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Terms of Service & User Agreement
        </h1>
        <div className="mt-10 max-w-2xl">
          <p>
            This Member Agreement (this "Agreement") contains the terms and
            conditions that govern your access to and use of the Service (as
            defined below) and is an agreement between Equitytraderx.com, Inc.
            ("we," "us," "our," "Equitytraderx.com," or the "Company") and you
            or the entity you represent ("you"). This Agreement takes effect
            when you sign a Subscription Order or, if earlier, when you use the
            Service (the "Effective Date"). You represent to us that you are
            lawfully able to enter into contracts (e.g., you are age of 18 or
            older). If you are entering into this Agreement for an entity, such
            as the company you work for, you represent to us that you have legal
            authority to bind that entity including, without limitation the
            ARBITRATION AND CLASS ACTION provisions found in Section 13.8 Please
            see Section 14 for definitions of certain capitalized terms used in
            this Agreement.
          </p>
          <p className="mt-8">1. Use of the Service.</p>
          <p className="mt-8">
            1.1 Generally. You may access and use the Service in accordance with
            this Agreement. You will adhere to all laws, rules, and regulations
            applicable to your use of the Service, including the Policies as
            defined in Section 13.
          </p>
          <p className="mt-8">
            1.2 Your Account. To access the Service, you must create an account
            associated with a valid e-mail address and phone number. You may
            only create one account per email address or phone number. You are
            responsible for all activities that occur under your account,
            regardless of whether the activities are undertaken by you, your
            employees or a third party (including a spouse) and, except to the
            extent caused by our breach of this Agreement, we and our affiliates
            are not responsible for unauthorized access to your account. You
            will contact us immediately if you believe an unauthorized third
            party may be using your account or if your account information is
            lost or stolen. You may terminate your account and this Agreement at
            any time in accordance with Section 7.
          </p>
          <p className="mt-8">
            1.3 Third-Party Content. Third-Party Content, such as newsletters,
            the information provided by our affiliates or third parties,
            software applications, or services provided by our affiliates or
            third parties, may be made available directly to you by other
            companies or individuals with or without separate terms and
            conditions or separate fees and charges. Because we may not have
            confirmed, screened or tested any Third Party Content, your use of
            any Third Party Content is at your sole risk.
          </p>
          <p className="mt-8">2. Changes.</p>
          <p className="mt-8">
            We may change, discontinue, or downgrade any of the Service
            (including the Service as a whole) or change or remove features or
            functionality of the Service from time to time.
          </p>
          <p className="mt-8">3. SMS Alert Services.</p>
          <p className="mt-8">
            3.1 Program Description: Trade related commentary and commentary
            alerts for breaking news based on news or newsworthy content.
          </p>
          <p className="mt-8">
            3.2 You can cancel the SMS service at any time. Just text "STOP" to
            the short code. After you send the SMS message "STOP" to us, we will
            send you an SMS message to confirm that you have been unsubscribed.
            After this, you will no longer receive SMS messages from us. If you
            want to join again, just sign up as you did the first time and we
            will start sending SMS messages to you again.
          </p>
          <p className="mt-8">
            3.3 If you are experiencing issues with the messaging program you
            can reply with the keyword HELP for more assistance, or you can get
            help directly at Equitytraderx.com/contact.
          </p>
          <p className="mt-8">
            3.4 Carriers are not liable for delayed or undelivered messages
          </p>
          <p className="mt-8">
            3.5 As always, message and data rates may apply for any messages
            sent to you from us and to us from you. Your messaging frequency
            will vary and you may receive several messages a day. If you have
            any questions about your text plan or data plan, it is best to
            contact your wireless provider.
          </p>
          <p className="mt-8">
            3.6 If you have any questions regarding privacy, please read our
            Privacy Statement.
          </p>
          <p className="mt-8">4. Data Privacy.</p>
          <p className="mt-8">
            You consent to our collection, use and disclosure of information
            associated with the Service in accordance with our Privacy
            Statement.
          </p>
          <p className="mt-8">5. Fees and Payment.</p>
          <p className="mt-8">
            5.1 Service Trial. Equitytraderx.com may offer an evaluation period,
            or trial, for its services for first-time subscribers. Customers are
            only allowed to evaluate the product only one (1) time over their
            lifetime. Customers having previously subscribed to any of
            EquityTraderX offerings will not benefit from the trial and will
            automatically be billed the subscription fee upon registration or
            renewal regardless of the mention "Trial" under the product
            description.
          </p>
          <p className="mt-8">
            5.2 Payment. EquityTraderX will charge the subscription fee and any
            other additional fees or charges you authorize on the credit card
            provided by you and starting on the date stated by your subscription
            invoice or other previous payment agreement established between us
            and you.
          </p>
          <p className="mt-8">
            5.3 Credit Card Authorization. By authorizing EquityTraderX to
            charge a credit card for the fees associated with your Service, you
            are authorizing EquityTraderX to automatically continue charging
            that card (or any replacement card if the original card is renewed,
            lost, stolen, or changed for any reason by the card issuer, and the
            issuer informs EquityTraderX of the new replacement card account)
            for all fees or charges associated with your subscription to the
            Service including any renewal fees as described below. EquityTraderX
            may elect to interrupt Service for fees that reach ten (10) days
            past due.{" "}
          </p>
          <p className="mt-8">
            5.4 Automatic Renewal of Subscription. When you subscribe to the
            Service your subscription will be set to automatically renew upon
            its expiration. This means that unless you explicitly cancel the
            Service prior to its expiration date, your Service will
            automatically renew for the same term. At the time of renewal, we
            will charge your credit card, or invoice you, the then-current fees
            to renew the Service.
          </p>
          <p className="mt-8">
            5.5 Cancellations and Refunds. All fees and charges paid by you in
            relation to the Service are non-refundable, except (i) you may
            cancel this Agreement at any time prior to midnight of the third
            business day as provided for in your Subscription Order following
            the renewal date or (ii) if we terminate this Agreement for
            convenience. Following cancellation, EquityTraderX reserves the
            right to (i) limit or restrict access to the Service and (ii) delete
            all Your Content.
          </p>
          <p className="mt-8">
            5.6 Changes in Price. EquityTraderX may, at any time, change the
            price of the Service or any part thereof, or institute new charges
            or fees. Price changes and institution of new charges implemented
            during an existing subscription term for the Service will apply to
            subsequent subscription terms and to all new members after the
            effective date of the change. If you do not agree to any such price
            changes, then you must cancel your account and stop using the
            Service. Your continued use of the Service after the effective date
            of any such change shall constitute your acceptance of such change.
          </p>
          <p className="mt-8">
            5.7 Taxes. Charges for Services hereunder do not include any
            federal, state, local or foreign taxes, duties or levies of any
            nature ("Taxes"). Any Taxes required to be paid by EquityTraderX as
            a result of your use of the Service (other than Taxes based on
            EquityTraderX income) shall be billed to and paid by you.
          </p>
          <p className="mt-8">
            5.8 Fee Disputes. You agree that if there are problems or questions
            regarding the Service that you will contact EquityTraderX for
            assistance and not dispute any charges from us unless you terminate
            for breach as provided in this Agreement. You agree to inform
            EquityTraderX of any credit card information changes, including
            expiration date. You represent and warrant that you are legally
            authorized to enter into Agreement including use of the credit
            associated with the account. You agree to provide EquityTraderX with
            at least three (3) days prior written notice to request termination
            of any recurring credit card authorization and transition to another
            form of billing.
          </p>
          <p className="mt-8">6. Temporary Suspension.</p>
          <p className="mt-8">
            6.1 Generally. We may suspend your right to access or use any
            portion or all of the Service immediately upon notice to you if we
            determine that:
          </p>
          <p className="mt-8">
            (a) your use of or registration for the Service (i) possess a
            security risk to the Service or any third party, (ii) may adversely
            impact the Service or the systems or Content of any other customer
            of EquityTraderX, (iii) may subject us, our affiliates, or any third
            party to direct or indirect liability (e.g. breach of
            confidentiality or non-disparagement), or (iv) may be fraudulent;
          </p>
          <p className="mt-8">
            (b) you are in breach of this Agreement, including if you are
            delinquent on your payment obligations for more than seven (7) days;
            or
          </p>
          <p className="mt-8">
            (c) you have ceased to operate in the ordinary course, made an
            assignment for the benefit of creditors or similar disposition of
            your assets, or become the subject of any investigation by any
            agency on your trading or other securities related activities,
            bankruptcy, reorganization, liquidation, dissolution or similar
            proceeding.
          </p>
          <p className="mt-8">
            6.2 Effect of Suspension. If we suspend your right to access or use
            any portion or all of the Service:
          </p>
          <p className="mt-8">
            (a) you remain responsible for all fees and charges you agreed to as
            of the Effective Date;
          </p>
          <p className="mt-8">
            (b) you will not be entitled to any credit for any period of
            suspension; and
          </p>
          <p className="mt-8">
            (c) we will not erase any of Your Content as a result of your
            suspension, except as specified elsewhere in this Agreement.
          </p>
          <p className="mt-8">
            Our right to suspend your use the Service is in addition to our
            right to terminate this Agreement pursuant to Section 6.2.
          </p>
          <p className="mt-8">7. Term; Termination.</p>
          <p className="mt-8">
            7.1 Term. The term of this Agreement will commence on the Effective
            Date and will remain in effect until terminated by you or us in
            accordance with Section 7.2.
          </p>
          <p className="mt-8">7.2 Termination.</p>
          <p className="mt-8">
            (a) Termination for Convenience. You may terminate this Agreement
            for any reason by (i) providing us written notice and (ii) closing
            your account for which we provide you Service and for which we
            provide an account closing mechanism. We may terminate this
            Agreement for any reason by providing you 30 days advance notice.
          </p>
          <p className="mt-8">(b) Termination for Cause.</p>
          <p className="mt-8">
            (i) By Either Party. Either party may terminate this Agreement for
            cause upon ten (10) days advance written notice to the other party
            if there is any material default or breach of this Agreement by the
            other party, unless the defaulting party has cured the material
            default or breach within the 10-day notice period.
          </p>
          <p className="mt-8">
            (ii) By Us. We may also terminate this Agreement immediately upon
            notice to you: (a) for cause, if any act or omission by you results
            in a suspension described in Section 7.1; (b) if our relationship
            with a third party partner who provides material elements we use to
            provide the Service expires, terminates or requires us to change the
            way we provide these elements as part of the Service; (c) if we
            believe providing the Service could create a substantial economic or
            technical burden or material security risk for us; (d) in order to
            comply with the law or requests of governmental entities; or (e) if
            we determine use of the Service by you or our provision of any of
            the Services to you has become impractical or unfeasible for any
            legal or regulatory reason.
          </p>
          <p className="mt-8">7.3 Effect of Termination.</p>
          <p className="mt-8">
            Generally, upon any termination of this Agreement:
          </p>
          <p className="mt-8">
            (a) all your rights under this Agreement immediately terminate;
          </p>
          <p className="mt-8">
            (b) you remain responsible for all fees and charges pursuant to
            Section 6;
          </p>
          <p className="mt-8">
            (c) you will immediately return or, if instructed by us, destroy all
            EquityTraderX Content in your possession; and
          </p>
          <p className="mt-8">
            (d) Sections 5.1, 5.2, 5.6, 7.3, 8 (except the license granted to
            you in Section 8.4), 9, 10, 11, 12, 13, and 14 will continue to
            apply in accordance with their terms.
          </p>
          <p className="mt-8">8. Proprietary Rights.</p>
          <p className="mt-8">
            8.1 Your Content. You or your licensors own all right, title, and
            interest in and to Your Content. Except as provided in this Section
            8, we obtain no rights under this Agreement from you or your
            licensors to Your Content, including any related intellectual
            property rights. You consent to our use of Your Content to provide
            the Service to you. We may disclose Your Content to provide the
            Service to you or to comply with any request of a governmental or
            regulatory body (including subpoenas or court orders).
          </p>
          <p className="mt-8">
            8.2 Adequate Rights. You represent and warrant to us that: (a) you
            or your licensors own all right, title, and interest in and to Your
            Content and Your Submissions; (b) you have all rights in Your
            Content and Your Submissions necessary to grant the rights
            contemplated by this Agreement; and (c) none of Your Content, Your
            Submissions of Your Content, Your Submissions or the Service will
            violate the Acceptable Use Policy.
          </p>
          <p className="mt-8">
            8.3 Service License. As between you and us, we or our affiliates or
            licensors own and reserve all right, title, and interest in and to
            the Service. We grant you a limited, revocable, non-exclusive,
            non-sublicensable, non-transferable license to do the following
            during the Term: (i) access and use the Services solely in
            accordance with this Agreement; and (ii) use the EquityTraderX
            Content solely in connection with your permitted use of the
            Services. Except as provided in this Section 8.3, you obtain no
            rights under this Agreement from us or our licensors to the Service,
            including any related intellectual property rights.
          </p>
          <p className="mt-8">
            8.4 License Restrictions. You may not use the Service in any manner
            or for any purpose other than as expressly permitted by this
            Agreement. You may not, or may not attempt to: (a) modify, alter,
            tamper with, or otherwise create derivative works of any
            intellectual property included in the Service, (b) reverse engineer,
            disassemble, or decompile the Service, (c) access or use the Service
            in a way intended to avoid incurring fees,(d) resell or sublicense
            the Service, (e) copy the look-and-feel or functionality of the
            EquityTraderX Marks or Site; (f) remove any EquityTraderX Marks
            without our prior written consent; (g) use, redistribute, encumber,
            sell, rent, lease, sublicense, or otherwise transfer rights to,
            and/or exploit, the Services for commercial purposes, or in a
            commercial manner; or (g) use our Service in any way that is not in
            compliance with all applicable laws, regulations and government
            authorizations, including but not limited to securities laws, export
            control, copyright, trademarks, secrecy, defamation, decency, and
            privacy related laws, regulations and authorizations. All licenses
            granted to you in this Agreement are conditional on your continued
            compliance this Agreement, and will immediately and automatically
            terminate if you do not comply with any term or condition of this
            Agreement. During and after the Term, you will not assert, nor will
            you authorize, assist, or encourage any third party to assert,
            against us or any of our affiliates, customers, vendors, business
            partners, or licensors, any patent infringement or other
            intellectual property infringement claim regarding any Service you
            have used.
          </p>
          <p className="mt-8">
            8.5 Suggestions. If you provide any Suggestions to us or our
            affiliates, we will own all right, title, and interest in and to the
            Suggestions, even if you have designated the Suggestions as
            confidential. We and our affiliates will be entitled to use the
            Suggestions without restriction. You hereby irrevocably assign to us
            all right, title, and interest in and to the Suggestions and agree
            to provide us any assistance we may require to document, perfect,
            and maintain our rights in the Suggestions.
          </p>
          <p className="mt-8">9. Indemnification.</p>
          <p className="mt-8">
            9.1 General. You will defend, indemnify, and hold harmless us, our
            affiliates and licensors, and each of their respective employees,
            officers, directors, and representatives from and against any
            claims, damages, losses, liabilities, costs, and expenses (including
            reasonable attorneys' fees) arising out of, relating to, or
            concerning: (a) your use of the Service; (b) breach of this
            Agreement or violation of applicable law by you; (c) Your Content or
            the combination of Your Content with other applications, content or
            processes, including any claim involving alleged infringement or
            misappropriation of third-party rights by Your Content or by the
            use, development, design, production, advertising or marketing of
            Your Content; or (d) a dispute between you and a third party. If we
            or our affiliates are obligated to respond to a third party subpoena
            or other compulsory legal order or process described above, you will
            also reimburse us for reasonable attorneys' fees, as well as our
            employees' and contractors' time and materials spent responding to
            the third party subpoena or other compulsory legal order or process
            at our then-current hourly rates.
          </p>
          <p className="mt-8">
            9.2 Process. We will promptly notify you of any claim subject to
            Section 9.1, but our failure to promptly notify you will only affect
            your obligations under Section 9.1 to the extent that our failure
            prejudices your ability to defend the claim. You may: (a) use
            counsel of your own choosing (subject to our written consent) to
            defend against any claim; and (b) settle the claim as you deem
            appropriate, provided that you obtain our prior written consent
            before entering into any settlement. We may also assume control of
            the defense and settlement of the claim at any time.
          </p>
          <p className="mt-8">10. Disclaimers.</p>
          <p className="mt-8">
            10.1 General. THE SERVICE IS PROVIDED "AS IS." WE AND OUR AFFILIATES
            AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
            WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE
            SERVICE OR THE THIRD PARTY CONTENT, INCLUDING ANY WARRANTY THAT THE
            SERVICE OR THIRD PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR
            FREE OF HARMFUL COMPONENTS, OR THAT ANY CONTENT, INCLUDING YOUR
            CONTENT OR THE THIRD PARTY CONTENT, WILL BE SECURE OR NOT OTHERWISE
            LOST OR DAMAGED. EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR
            AFFILIATES AND LICENSORS DISCLAIM ALL WARRANTIES, INCLUDING ANY
            IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS
            FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR QUIET ENJOYMENT, AND
            ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF
            TRADE.
          </p>
          <p className="mt-8">
            10.2 Financial and Other. NEITHER EquityTraderX INDIVIDUALLY, NOR
            ITS WEBSITES INCLUDING, BUT NOT LIMITED TO THE StockEquityTraderX
            SITE, NOR ANY OF ITS OFFICERS, EMPLOYEES, REPRESENTATIVES, AGENTS,
            INDEPENDENT CONTRACTORS, OR SUBCONTRACTORS ARE, IN SUCH CAPACITIES,
            LICENSED FINANCIAL ADVISORS, REGISTERED INVESTMENT ADVISORS, OR
            REGISTERED BROKER-DEALERS UNLESS OTHERWISE EXPRESSLY PROVIDED.
          </p>
          <p className="mt-8">
            EquityTraderX MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE
            ACCURACY OR COMPLETENESS OF THE INFORMATION PROVIDED IN CONNECTION
            WITH THE SERVICE. NO INFORMATION PROVIDED BY EquityTraderX,
            INCLUDING BUT NOT LIMITED TO EquityTraderX, IS INTENDED AS
            SECURITIES BROKERAGE, INVESTMENT, TAX, ACCOUNTING OR LEGAL ADVICE,
            AS AN OFFER OR SOLICITATION OF AN OFFER TO SELL OR BUY, OR AS AN
            ENDORSEMENT, RECOMMENDATION OR SPONSORSHIP OF ANY COMPANY, SECURITY
            OR FUND OR AS ADVICE TAILORED TO YOUR OR ANY MEMBER'S PARTICULAR
            SITUATION. ANY URL LINKS OR COMPANY NAMES OR TICKER SYMBOLS ARE
            OFFERED AS A MATTER OF CONVENIENCE AND NOTHING CONTAINED IN THIS
            AGREEMENT OR IN THE SERVICE CONSTITUTES A SOLICITATION,
            RECOMMENDATION, PROMOTION, ENDORSEMENT, OR OFFER BY EquityTraderX OF
            ANY PARTICULAR SECURITY, TRANSACTION, OR INVESTMENT.
          </p>
          <p className="mt-8">
            EquityTraderX, INCLUDING BUT NOT LIMITED TO EquityTraderX AND
            AFFILIATES, MAY HAVE POSITIONS IN THE SECURITIES MENTIONED AND MAY
            ENTER AND EXIT SUCH POSITIONS AT ANY TIME AND FOR ANY REASON.
          </p>
          <p className="mt-8">
            THE RISK OF LOSS IN TRADING SECURITIES, OPTIONS, FUTURES, FOREX, AND
            COMMODITIES CAN BE SUBSTANTIAL AND ARE NOT SUITABLE FOR ALL
            INVESTORS. YOU MUST CONSIDER ALL RELEVANT RISK FACTORS, INCLUDING
            YOUR OWN PERSONAL FINANCIAL SITUATION, BEFORE TRADING. YOU ASSUME
            THE RISK OF ANY AND ALL FINANCIAL INVESTMENTS YOU MAKE.
            EquityTraderX IS NOT RESPONSIBLE FOR ANY FINANCIAL INVESTMENTS YOU
            MAKE.
          </p>
          <p className="mt-8">
            RESULTS VARY AND PAST PERFORMANCE IS NOT INDICATIVE OF FUTURE
            RETURNS.
          </p>
          <p className="mt-8">11. Limitations of Liability.</p>
          <p className="mt-8">
            WE AND OUR AFFILIATES OR LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY
            DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
            DAMAGES (INCLUDING DAMAGES FOR LOSS OF INVESTMENTS, PROFITS,
            GOODWILL, USE, OR DATA), EVEN IF A PARTY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. FURTHER, NEITHER WE NOR ANY OF OUR
            AFFILIATES OR LICENSORS WILL BE RESPONSIBLE FOR ANY COMPENSATION,
            REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION WITH: (A) YOUR
            INABILITY TO USE THE SERVICES, INCLUDING AS A RESULT OF ANY (I)
            TERMINATION OR SUSPENSION OF THIS AGREEMENT OR YOUR USE OF OR ACCESS
            TO THE SERVICE, (II) OUR DISCONTINUATION OF ANY OR ALL OF THE
            SERVICE, OR, (III) ANY UNANTICIPATED OR UNSCHEDULED DOWNTIME OF ALL
            OR A PORTION OF THE SERVICE FOR ANY REASON, INCLUDING AS A RESULT OF
            POWER OUTAGES, SYSTEM FAILURES OR OTHER INTERRUPTIONS; (B) ANY
            INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY YOU IN CONNECTION WITH
            THIS AGREEMENT OR YOUR USE OF OR ACCESS TO THE SERVICE; OR (C) ANY
            UNAUTHORIZED ACCESS TO, ALTERATION OF, OR THE DELETION, DESTRUCTION,
            DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT OR OTHER DATA.
            IN ANY CASE, OUR AND OUR AFFILIATES' AND LICENSORS' AGGREGATE
            LIABILITY UNDER THIS AGREEMENT WILL BE LIMITED TO THE AMOUNT YOU
            ACTUALLY PAY US UNDER THIS AGREEMENT FOR THE SERVICE THAT GAVE RISE
            TO THE CLAIM DURING THE 12 MONTHS PRECEDING THE CLAIM.
          </p>
          <p className="mt-8">
            12. Confidentiality; Publicity; Non-Disparagement; Restrictions.
          </p>
          <p className="mt-8">
            12.1 Confidentiality. You may use EquityTraderX Confidential
            Information only in connection with your use of the Service as
            permitted under this Agreement. You will not disclose EquityTraderX
            Confidential Information during the term or at any time during the
            five (5) year period following the end of the term. You will take
            all reasonable measures to avoid disclosure, dissemination or
            unauthorized use of EquityTraderX Confidential Information,
            including, at a minimum, those measures you take to protect your own
            confidential information of a similar nature. The foregoing
            restrictions shall not apply to the extent you must defend yourself
            in an action related to this Agreement, or to respond to an
            investigation by a governmental agency initiated by that agency or
            to report any truthful incidents of illegal conduct directed at you
            to law enforcement.
          </p>
          <p className="mt-8">
            12.2 Non-Disparagement. The parties agree that during the term of
            this Agreement and for a period of twenty-four (24) months
            immediately following expiration or termination for any reason,
            whether with or without cause, each shall not, not to (i) make any
            statements, written or verbal, or cause or encourage others to make
            any statements, written or verbal, that defame, disparage or in any
            way criticize the personal or business reputation, practices, or
            conduct of the other or, as to EquityTraderX, its respective
            employees, officers, directors, managers, members and affiliates or
            with respect to you (ii) publish or attempt to publish any
            narrative, fictional or otherwise, concerning or based, in whole or
            in part, on EquityTraderX without EquityTraderX prior written
            consent or without leave of court. Each party acknowledges and
            agrees that this prohibition extends to statements, written or
            verbal, made to anyone, including but not limited to, the news
            media, investors, potential investors, any board of directors or
            advisory board or directors, industry analysts, competitors,
            strategic partners, vendors, employees (past and present),
            EquityTraderX Site users, clients or potential clients.
          </p>
          <p className="mt-8">12.3 Restrictions.</p>
          <p className="mt-8">You will not:</p>
          <p className="mt-8">
            (a) issue any written statements or communicate, directly or
            indirectly, in any mode or fashion, with any person, entity,
            organization, or agency regarding or concerning, including, without
            limitation, the use of anonymous email or other communications
            through a third party, with respect to this Agreement or your use of
            the Service including, but not limited to our proprietary
            methodology that underscores the Service. You will not misrepresent
            or embellish the relationship between us and you (including by
            expressing or implying that we support, sponsor, or endorse or your
            business endeavors), or express or imply any relationship or
            affiliation between us and you or any other person or entity except
            as expressly permitted by this Agreement.
          </p>
          <p className="mt-8">
            (b) publish or attempt to publish on any website or to any third
            party any narrative, fictional or otherwise, concerning or based, in
            whole or in part, the Service or this Agreement without
            EquityTraderX prior written consent or without leave of court; or
          </p>
          <p className="mt-8">
            (c) interfere with EquityTraderX (including its officers, managers,
            employees, and affiliates) personal or business relationships
            concerning the Service or this Agreement.
          </p>
          <p className="mt-8">12.4 Generally.</p>
          <p className="mt-8">
            The Parties understand and agree that this Section 12 is a material
            provision of this Agreement and that any breach of this Section
            shall be a material breach of this Agreement, and that EquityTraderX
            would be irreparably harmed by any violation and that it would have
            no adequate remedy at law for such a violation. As such, you
            therefore consent to immediate injunctive relief by a court of
            competent jurisdiction as a remedy for any violation of these terms
            of the Agreement. You further acknowledge that any actions taken not
            in compliance with this Section are considered as purposefully
            directed at EquityTraderX and that any actions for alleged injuries
            arise out or relate to activities in connection violations of this
            Section.
          </p>
          <p className="mt-8">13. Miscellaneous.</p>
          <p className="mt-8">
            13.1 Force Majeure. We and our affiliates will not be liable for any
            delay or failure to perform any obligation under this Agreement
            where the delay or failure results from any cause beyond our
            reasonable control, including acts of God, labor disputes or other
            industrial disturbances, systemic electrical, telecommunications, or
            other utility failures, earthquake, storms or other elements of
            nature, blockages, embargoes, riots, acts or orders of government,
            acts of terrorism, or war.
          </p>
          <p className="mt-8">
            13.2 Independent Contractors; Non-Exclusive Rights. We and you are
            independent contractors, and neither party, nor any of their
            respective affiliates, is an agent of the other for any purpose or
            has the authority to bind the other. Both parties reserve the right
            (a) to develop or have developed for it products, services,
            concepts, systems, or techniques that are similar to or compete with
            the products, services, concepts, systems, or techniques developed
            or contemplated by the other party and (b) to assist third party
            developers or systems integrators who may offer products or services
            which compete with the other party's products or services.
          </p>
          <p className="mt-8">
            13.3 No Third Party Beneficiaries. This Agreement does not create
            any third party beneficiary rights in any individual or entity that
            is not a party to this Agreement.
          </p>
          <p className="mt-8">13.4 Notice.</p>
          <p className="mt-8">
            (a) To You. We may provide any notice to you under this Agreement
            by: (i) posting a notice on the EquityTraderX Site; or (ii) sending
            a message to the email address then associated with your account.
            Notices we provide by posting on the EquityTraderX Site will be
            effective upon posting and notices we provide by email will be
            effective when we send the email. It is your responsibility to keep
            your email address current. You will be deemed to have received any
            email sent to the email address then associated with your account
            when we send the email, whether or not you actually receive the
            email.
          </p>
          <p className="mt-8">
            (b) To Us. To give us notice under this Agreement, you must contact
            Equitytraderx.com as follows: (i) by personal delivery, overnight
            courier or registered or certified mail to Equitytraderx.com, We may
            update the facsimile number or address for notices to us by posting
            a notice on the EquityTraderX Site. Notices provided by personal
            delivery will be effective immediately. Notices provided by
            facsimile transmission or overnight courier will be effective one
            business day after they are sent. Notices provided registered or
            certified mail will be effective three business days after they are
            sent.
          </p>
          <p className="mt-8">
            13.5 Assignment. You will not assign this Agreement, or delegate or
            sublicense any of your rights under this Agreement, without our
            prior written consent. Any assignment or transfer in violation of
            this Section 13.5 will be void. Subject to the foregoing, this
            Agreement will be binding upon, and inure to the benefit of the
            parties and their respective successors and assigns.
          </p>
          <p className="mt-8">
            13.6 No Waivers. The failure by us to enforce any provision of this
            Agreement will not constitute a present or future waiver of such
            provision nor limit our right to enforce such provision at a later
            time. All waivers by us must be in writing to be effective.
          </p>
          <p className="mt-8">
            13.7 Severability. If any portion of this Agreement is held to be
            invalid or unenforceable, the remaining portions of this Agreement
            will remain in full force and effect. Any invalid or unenforceable
            portions will be interpreted to effect and intent of the original
            portion. If such construction is not possible, the invalid or
            unenforceable portion will be severed from this Agreement but the
            rest of the Agreement will remain in full force and effect.
          </p>
          <p className="mt-8">
            13.8 Governing Law; Procedures for Dispute Resolution; 30-day
            Arbitration and Opt-Out Policy. ALL DISPUTES WITH Equitytraderx.com,
            INC. ARISING IN ANY WAY FROM THIS AGREEMENT OR THE USE OF THE
            SERVICES SHALL BE RESOLVED EXCLUSIVELY THROUGH FINAL AND BINDING
            ARBITRATION, AND NOT BY A COURT OR JURY. ANY SUCH DISPUTE SHALL NOT
            BE COMBINED OR CONSOLIDATED WITH A DISPUTE INVOLVING ANY OTHER
            PERSON'S OR ENTITY'S PRODUCT OR CLAIM, AND SPECIFICALLY, WITHOUT
            LIMITATION OF THE FOREGOING, SHALL NOT UNDER ANY CIRCUMSTANCES
            PROCEED AS PART OF A CLASS ACTION. THE ARBITRATION SHALL BE
            CONDUCTED BEFORE A SINGLE ARBITRATOR, WHOSE AWARD MAY NOT EXCEED, IN
            FORM OR AMOUNT, THE RELIEF ALLOWED BY THE APPLICABLE LAW. The
            arbitration shall be conducted according to the American Arbitration
            Association (AAA) Commercial Arbitration Rules applicable to
            consumer disputes. The AAA Rules are available online at adr.org or
            by calling the AAA at 1-800-778-7879. This arbitration provision is
            entered pursuant to the Federal Arbitration Act. The laws of the
            State of Texas, without reference to its choice of laws principles,
            shall govern the interpretation of the Agreement and all disputes
            that are subject to this arbitration provision. The arbitrator shall
            decide all issues of interpretation and application of this
            arbitration provision. For any arbitration in which your total
            damage claims, exclusive of attorney fees and expert witness fees,
            are $5,000.00 or less ("Small Claim"), the arbitrator may, if you
            prevail, award your reasonable attorney fees, expert witness fees
            and costs as part of any award, but may not grant Equitytraderx.com,
            Inc. its attorney fees, expert witness fees or costs unless it is
            determined that the claim was brought in bad faith. In a Small Claim
            case, you shall be required to pay no more than half of the total
            administrative, facility and arbitrator fees, or $5,000.00 of such
            fees, whichever is less, and Equitytraderx.com, Inc. shall pay the
            remainder of such fees. Administrative, facility and arbitrator fees
            for arbitrations in which your total damage claims, exclusive of
            attorney fees and expert witness fees, exceed $5,000.00 ("Large
            Claim") shall be determined according to AAA rules. In a Large Claim
            case, the arbitrator may grant to the prevailing party, or apportion
            among the parties, reasonable attorney fees, expert witness fees and
            costs. Judgment may be entered on the arbitrator's award in any
            court of competent jurisdiction.
          </p>
          <p className="mt-8">
            This arbitration provision also applies to claims against
            Equitytraderx.com, Inc.'s employees, representatives and affiliates
            if any such claim arises from the Product's sale, condition or
            performance.
          </p>
          <p className="mt-8">
            You may opt out of this dispute resolution procedure by providing
            notice to Equitytraderx.com, Inc. no later than 30 calendar days
            from the date of your registration of your EquityTraderX Pro
            Membership. To opt out, you must send notice by e-mail to
            legal@Equitytraderx.com, with the subject line: "Arbitration Opt
            Out." You must include in the opt out email (a) your name, address
            and, to the extent applicable, the email address used in connection
            with your EquityTraderX Pro Membership and (b) the date on which you
            first registered your EquityTraderX Pro Membership.
          </p>
          <p className="mt-8">
            13.9 Advice of Counsel. EACH PARTY ACKNOWLEDGES THAT, IN EXECUTING
            THIS AGREEMENT, SUCH PARTY HAS HAD THE OPPORTUNITY TO SEEK THE
            ADVICE OF INDEPENDENT LEGAL COUNSEL, AND HAS READ AND UNDERSTOOD ALL
            OF THE TERMS AND PROVISIONS OF THIS AGREEMENT. THIS AGREEMENT SHALL
            NOT BE CONSTRUED AGAINST ANY PARTY BY REASON OF THE DRAFTING OR
            PREPARATION HEREOF.
          </p>
          <p className="mt-8">
            13.10 Entire Agreement; English Language. This Agreement includes
            the Policies and is the entire agreement between you and us
            regarding the subject matter of this Agreement. This Agreement
            supersedes all prior or contemporaneous representations,
            understandings, agreements, or communications between you and us,
            whether written or verbal, regarding the subject matter of this
            Agreement. We will not be bound by, and specifically object to, any
            term, condition or other provision which is different from or in
            addition to the provisions of this Agreement (whether or not it
            would materially alter this Agreement) and which is submitted by you
            in any order, receipt, acceptance, confirmation, correspondence or
            other document. If the terms of this document are inconsistent with
            the terms contained in any Policy, the terms contained in this
            Agreement will control.
          </p>
          <p className="mt-8">14. Definitions.</p>
          <p className="mt-8">
            "EquityTraderX Confidential Information" means all nonpublic
            information disclosed by us, our affiliates, business partners or
            our or their respective employees, contractors or agents that is
            designated as confidential or that, given the nature of the
            information or circumstances surrounding its disclosure, reasonably
            should be understood to be confidential. EquityTraderX Confidential
            Information includes: (a) nonpublic information relating to our or
            our affiliates or business partners' technology, customers, business
            plans, promotional and marketing activities, finances and other
            business affairs including, but not limited to our proprietary
            methodology providing information and education to you so that you
            may conduct your own investment research and consider your own
            investment decisions ; (b) third-party information that we are
            obligated to keep confidential; and (c) the nature, content and
            existence of any discussions or negotiations between you and us or
            our affiliates. EquityTraderX Confidential Information does not
            include any information that: (i) is or becomes publicly available
            without breach of this Agreement; (ii) can be shown by documentation
            to have been known to you at the time of your receipt from us; (iii)
            is received from a third party who did not acquire or disclose the
            same by a wrongful or tortious act; (iv) can be shown by
            documentation to have been independently developed by you without
            reference to the EquityTraderX Confidential Information; or (v)
            discloses your own trading activities but does not mention
            EquityTraderX proprietary methodology and other aspects of the
            Service.
          </p>
          <p className="mt-8">
            "EquityTraderX Content" means Content we or any of its affiliates
            make available in connection with the Service or on the
            EquityTraderX Software and/or Site to allow access to and use of the
            Service; Documentation; software libraries; command line tools; and
            other related technology. EquityTraderX Content does not include the
            Service.
          </p>
          <p className="mt-8">
            "EquityTraderX Marks" means any trademarks, service marks, service
            or trade names, logos, and other designations of EquityTraderX and
            its affiliates that we may make available to you in connection with
            the Service or this Agreement.
          </p>
          <p className="mt-8">
            "Equity Trader X Site(s)" means Equitytraderx.com
            investimonials.com, steadytrade.com and any successor or related
            site designated by us.
          </p>
          <p className="mt-8">
            "Content" means software (including machine images), data, text,
            audio, video, images, DVDs or other content.
          </p>
          <p className="mt-8">
            "Documentation" means the user guides and other written materials
            provided for in connection with the Service, as we may update from
            time to time.
          </p>
          <p className="mt-8">
            "Policies" means the Acceptable Use Policy, the Site Terms, and all
            restrictions described in the EquityTraderX Content and on the
            EquityTraderX Site, and any other policy or terms referenced in or
            incorporated into this Agreement.
          </p>
          <p className="mt-8">
            "Privacy Statement" means the privacy statement currently referenced
            at https://Equitytraderx.com/privacy-policy/ , as we may update from
            time to time.
          </p>
          <p className="mt-8">
            "Service" means each of the services made available by us or our
            affiliates, including any web services described in the Site Terms,
            the EquityTraderX Content, the EquityTraderX Marks, the
            EquityTraderX Site, and any other product or service provided by us
            under this Agreement. Service does not include Third Party Content.
          </p>
          <p className="mt-8">
            "Suggestions" means all suggested improvements to the Service that
            you provide to us.
          </p>
          <p className="mt-8">
            "Term" means the term of this Agreement described in Section 6.1.
          </p>
          <p className="mt-8">
            "Third Party Content" means Content made available to you by any
            third party on the EquityTraderX Site or in conjunction with the
            Service.
          </p>
          <p className="mt-8">
            "Your Content" means Content you (a) disclose, share, or upload on
            or to the Service, (b) cause to interface with the Services, or (c)
            upload to the Service under your account or otherwise transfer,
            process, use or store in connection with your account.
          </p>
          <p className="mt-8">
            "Your Submissions" means Content that you post or otherwise submit
            to developer forums, sample code repositories, public data
            repositories, or similar community-focused areas of the
            EquityTraderX Site or the Service.
          </p>
        </div>
      </div>
    </div>
  );
}
