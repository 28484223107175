import React, { useState, useEffect } from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import Loading from "./components/Loading";
import NewsGridItem from "./components/NewsGridItem";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { getTitleToSlug } from "./components/utils";

function getSmallImageUrl(item) {
  const smallImage = item.image.find((item) => item.size === "small");

  return smallImage ? smallImage.url : "/default-news.png";
}

export default function News({ backgroundColor }) {
  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const bgColorClass = backgroundColor || "bg-white";
  const navigate = useNavigate();
  const location = useLocation();

  const itemsPerPage = 39;

  const getCurrentPageFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return parseInt(queryParams.get("page")) || 1;
  };

  async function fetchNews(page, searchTerm = "") {
    setIsLoading(true);
    try {
      const url = new URL(
        `/api/news?page=${page}&limit=${itemsPerPage}`,
        window.location.origin
      );
      if (searchTerm) {
        url.searchParams.append("search", searchTerm);
      }

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to fetch news");
      }
      const { news, totalPages } = await response.json();

      setNewsItems(news || []);
      setTotalPages(totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const currentPage = getCurrentPageFromUrl();
    fetchNews(currentPage, searchTerm);
  }, [location]);

  const handlePageClick = (event) => {
    navigate(`/news?page=${event.selected + 1}`);
  };

  async function onSearch() {
    navigate(`/news?page=1`);
    await fetchNews(getCurrentPageFromUrl(), searchTerm);
  }

  return (
    <div className={`${bgColorClass}`}>
      <main className="isolate">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center mb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Latest Financial News
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Stay ahead with swift updates and insights on pivotal financial
              trends.
            </p>
          </div>
          <div>
            <div className="relative mb-8 flex items-center">
              <input
                type="text"
                name="search"
                id="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearch();
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Quick search"
              />
              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <button onClick={() => onSearch()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#6b7280"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {isLoading ? (
            <Loading center={false} />
          ) : (
            <div>
              <div className="grid gap-6 lg:grid-cols-4">
                {newsItems.map((item, index) => (
                  <NewsGridItem
                    key={index}
                    imageUrl={
                      item.isETXPost
                        ? item.smallImage.fields.file.url
                        : getSmallImageUrl(item)
                    }
                    link={`/news/${item.id}/${getTitleToSlug(item.title)}`}
                    title={item.title}
                    source={
                      item.isETXPost ? item.author.fields.name : item.author
                    }
                    date={new Date(
                      item.isETXPost ? item.postDate : item.created
                    ).toLocaleDateString()}
                    isBig={
                      index === 0 ||
                      index === 15 ||
                      index === 26 ||
                      index === 41
                    }
                  />
                ))}
              </div>

              <ReactPaginate
                previousLabel={
                  <div className="flex items-center">
                    <ArrowLongLeftIcon
                      className="mr-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Previous
                  </div>
                }
                nextLabel={
                  <div className="flex items-center">
                    Next
                    <ArrowLongRightIcon
                      className="ml-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                }
                pageCount={totalPages}
                onPageChange={handlePageClick}
                containerClassName={
                  "pt-12 flex items-center justify-center border-t border-gray-200 px-4 sm:px-0"
                }
                pageLinkClassName={
                  "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }
                breakLabel={"..."}
                breakClassName={
                  "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
                }
                activeLinkClassName={
                  "underline border-indigo-500 text-indigo-600"
                }
                previousLinkClassName={
                  "mr-4 inline-flex items-center border-t-2 border-transparent pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }
                nextLinkClassName={
                  "ml-4 inline-flex items-center border-t-2 border-transparent pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }
                disabledClassName={"hidden"}
                forcePage={getCurrentPageFromUrl() - 1}
              />
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
