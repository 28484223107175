import StockWidgets from "./../components/StockWidgets";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SearchStocks() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [symbol, setSymbol] = useState(searchParams.get("symbol") || "");
  const [submittedSymbol, setSubmittedSymbol] = useState(symbol); // New state variable for the submitted symbol

  useEffect(() => {
    const urlSymbol = searchParams.get("symbol");
    if (urlSymbol) {
      setSymbol(urlSymbol.toUpperCase());
      setSubmittedSymbol(urlSymbol.toUpperCase());
    }
  }, [searchParams]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSubmittedSymbol(symbol);
    setSearchParams({ symbol: symbol });
    navigate(`?symbol=${symbol}`);
  };

  const handleChange = (e) => {
    setSymbol(e.target.value.toUpperCase());
  };

  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Search Stocks
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Discover and analyze your next investment opportunity.
          </p>
        </div>

        <div className="pt-8">
          <form onSubmit={handleSearch}>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                value={symbol}
                onChange={handleChange}
                type="search"
                id="default-search"
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                placeholder="Enter Ticker Symbol"
                required
              />
              <button
                type="submit"
                className="text-white absolute end-2.5 bottom-2.5 bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
              >
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="mt-8">
          {submittedSymbol !== "" ? (
            <StockWidgets incomingSymbol={submittedSymbol} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
