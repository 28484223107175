import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "./../components/Loading";
import BlogPostGrid from "./../components/BlogPostGrid";
import { useBlogPosts } from "./../components/UseBlogPosts";
import { toSlug } from "./../components/utils";

export default function CategoryPage() {
  const { categoryId } = useParams();
  const filterCondition = (item) => toSlug(item.fields.category) === categoryId;
  const { posts, isLoading, categoryTitle } = useBlogPosts(filterCondition);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {categoryTitle}
          </h2>
        </div>
        <BlogPostGrid posts={posts} />
      </div>
    </div>
  );
}
