import React, { useState, useEffect } from "react";
import Loading from "./Loading";
import NewsGridItem from "./NewsGridItem";
import { getTitleToSlug } from "./utils";

function getSmallImageUrl(item) {
  const smallImage = item.image.find((item) => item.size === "small");

  return smallImage ? smallImage.url : "/default-news.png";
}

const StockTickerPosts = ({ symbol }) => {
  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchNews() {
      try {
        const response = await fetch("/api/news/symbol/" + symbol);
        const data = await response.json();
        setIsLoading(false);
        setNewsItems(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }
    fetchNews();
  }, [symbol]);

  if (isLoading) {
    return <Loading center={true} />;
  }

  return (
    <div
      className={`grid gap-6 lg:grid-cols-4 ${
        newsItems.length === 0 ? "" : "pt-4"
      }`}
    >
      {newsItems.map((item, index) => (
        <NewsGridItem
          key={index}
          imageUrl={getSmallImageUrl(item)}
          link={`/news/${item.id}/${getTitleToSlug(item.title)}`}
          title={item.title}
          source={item.author}
          date={new Date(item.created).toLocaleDateString()}
          isBig={false}
        />
      ))}
    </div>
  );
};

export default StockTickerPosts;
