import { useCSRFToken } from "./../CSRFTokenContext";
import { useUser } from "./../UserContext";
import { useState, useEffect } from "react";
import Loading from "../components/Loading";

export default function Settings() {
  const { state } = useUser();
  const { csrfToken } = useCSRFToken();
  const user = state.user;
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [changePasswordErr, setChangePasswordErr] = useState("");
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState("");
  const [logoutCurrentPass, setLogoutCurrentPass] = useState("");
  const [logoutAllErr, setLogoutAllErr] = useState("");
  const [logoutAllSuccess, setLogoutAllSuccess] = useState("");
  const [logoutAllLoading, setLogoutAllLoading] = useState(false);

  const [deleteAccountErr, setDeleteAccountErr] = useState("");
  const [deleteAccountSuccess, setDeleteAccountSuccess] = useState("");
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const [fetchedSubscriptionStatus, setFetchedSubscriptionStatus] =
    useState(null);

  const fetchSubscriptionStatus = async () => {
    try {
      const response = await fetch("/api/subscription-status", {
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setFetchedSubscriptionStatus(data);
        return data;
      } else {
        console.error("Failed to fetch subscription status.");
      }
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  const handleDeleteAccount = async () => {
    setDeleteAccountLoading(true);

    let recentlyFetchedSubscriptionStatus = await fetchSubscriptionStatus();

    if (
      recentlyFetchedSubscriptionStatus.status === "active" &&
      !recentlyFetchedSubscriptionStatus.setToCancel
    ) {
      setDeleteAccountLoading(false);
      setDeleteAccountErr(
        "You cannot delete your account while an active subscription is present."
      );
      return;
    }

    const confirmDeletion = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );
    if (confirmDeletion) {
      const password = window.prompt("Please enter your password to confirm:");
      if (password) {
        try {
          const response = await fetch("/api/delete-account", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ password }),
            credentials: "include",
          });

          const data = await response.json();

          setDeleteAccountLoading(false);
          if (response.ok) {
            setTimeout(() => {
              window.location.href = "/login";
            }, 3000);
            setDeleteAccountSuccess(data.message);
          } else {
            setDeleteAccountErr(data.message || "Failed to delete account.");
          }
        } catch (error) {
          console.error("Failed to delete account:", error);
          setDeleteAccountLoading(false);
          setDeleteAccountErr(
            "An unexpected error occurred. Please try again or contact support."
          );
        }
      } else {
        setDeleteAccountLoading(false);
        setDeleteAccountErr("Password required to delete your account.");
      }
    } else {
      setDeleteAccountLoading(false);
      setDeleteAccountErr("");
      setDeleteAccountSuccess("");
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setChangePasswordErr("");
    setChangePasswordSuccess("");
    setChangePasswordLoading(true);

    if (newPassword !== confirmNewPassword) {
      setChangePasswordLoading(false);
      setChangePasswordErr(
        "New password and confirm new password do not match."
      );
      return;
    }

    try {
      const response = await fetch("/change-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ currentPassword, newPassword }),
      });

      const data = await response.json();
      setChangePasswordLoading(false);
      if (response.ok) {
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
        setChangePasswordSuccess(
          "Password updated successfully. You will now be logged out of all sessions."
        );
      } else {
        setChangePasswordErr(data.message);
      }
    } catch (error) {
      console.error(error);
      setChangePasswordLoading(false);
      setChangePasswordErr(
        "Failed to change password. Please try again later or contact support."
      );
    }
  };

  const handleLogoutAllSessions = async (e) => {
    e.preventDefault();
    setLogoutAllErr("");
    setLogoutAllSuccess("");
    setLogoutAllLoading(true);

    try {
      const response = await fetch("/api/logout-all", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ currentPassword: logoutCurrentPass }),
      });

      const data = await response.json();
      setLogoutAllLoading(false);
      if (response.ok) {
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
        setLogoutAllSuccess("Successfully logged out of all sessions.");
      } else {
        setLogoutAllErr(data.message);
      }
    } catch (error) {
      console.error("Failed to log out from all sessions:", error);
      setLogoutAllLoading(false);
      setLogoutAllErr(
        "An error occurred while trying to log out from all sessions."
      );
    }
  };

  const unlinkDiscordAccount = async () => {
    const confirmUnlink = window.confirm(
      "Are you sure you want to unlink your Discord account?"
    );
    if (confirmUnlink) {
      try {
        const response = await fetch("/api/user/discord", {
          method: "DELETE",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        });
        const data = await response.json();
        if (response.ok) {
          alert(data.message);
          window.location.reload();
        } else {
          throw new Error(data.error || "Failed to unlink Discord account.");
        }
      } catch (error) {
        console.error(error);
        alert("Failed to unlink Discord account. Please contact support....");
      }
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="pt-4">
        <main>
          {/* Settings forms */}
          <div className="divide-y divide-white/5">
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-700">
                  Currently Logged In As
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {user.first_name} {user.last_name}
                </p>
              </div>

              <div className="md:col-span-2 flex items-end">
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {user.username}
                </p>
              </div>
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              {user.discord ? (
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-700">
                    Discord Account
                  </h2>
                  <div className="flex pt-4 items-end">
                    <img
                      className="mr-4 rounded-full w-16"
                      alt="Discord avatar"
                      src={`https://cdn.discordapp.com/avatars/${user.discord.discord_id}/${user.discord.avatar}.png`}
                    />
                    <div className="flex flex-col">
                      <span className="text-sm font-bold pb-2">
                        @{user.discord.username}
                      </span>
                      <a
                        href="https://discord.gg/mTmRVnYEk5"
                        alt="open discord"
                        className="text-indigo-500 font-semibold"
                      >
                        Open Discord <span aria-hidden="true">→</span>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-700">
                    Link Discord Account
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Connect your Discord account to enhance your experience.
                  </p>
                </div>
              )}

              {user.discord ? (
                <div className="md:col-span-2 flex items-end">
                  <button
                    type="button"
                    onClick={unlinkDiscordAccount}
                    className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700"
                  >
                    Unlink Discord Account
                  </button>
                </div>
              ) : (
                <div className="md:col-span-2 flex items-end">
                  <button
                    type="button"
                    onClick={() => {
                      const isLocalhost =
                        window.location.hostname === "localhost" ||
                        window.location.hostname === "127.0.0.1";
                      window.location.href = isLocalhost
                        ? "http://localhost:3001/api/auth/discord"
                        : "/api/auth/discord";
                    }}
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700"
                  >
                    Link Discord Account
                  </button>
                </div>
              )}
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-700">
                  Manage Subscription
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Update your subscription status to ETX.
                </p>
                {fetchedSubscriptionStatus &&
                fetchedSubscriptionStatus.status === "active" ? (
                  <p className="mt-1 text-sm leading-6 text-green-600 drop-shadow-sm">
                    Active Subscription
                  </p>
                ) : (
                  <p className="mt-1 text-sm leading-6 text-red-600 drop-shadow-sm">
                    Canceled Subscription
                  </p>
                )}
                {fetchedSubscriptionStatus &&
                fetchedSubscriptionStatus.subscriptionMessage ? (
                  <p className="mt-1 text-sm leading-6 text-gray-600 drop-shadow-sm">
                    {fetchedSubscriptionStatus.subscriptionMessage}
                  </p>
                ) : null}
              </div>

              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => {
                    const isLocalhost =
                      window.location.hostname === "localhost" ||
                      window.location.hostname === "127.0.0.1";
                    window.location.href = isLocalhost
                      ? "http://localhost:3001/create-customer-portal-session"
                      : "/create-customer-portal-session";
                  }}
                  className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Manage Subscription
                </button>
              </div>
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-700">
                  Change password
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Update your password associated with your account.
                </p>
              </div>

              <form className="md:col-span-2" onSubmit={handleChangePassword}>
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="col-span-full">
                    <label
                      htmlFor="current-password"
                      className="block text-sm font-medium leading-6 text-gray-600"
                    >
                      Current password
                    </label>
                    <div className="mt-2">
                      <input
                        id="current-password"
                        name="current_password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        type="password"
                        placeholder="********"
                        autoComplete="current-password"
                        className="px-3 py-2 block w-full rounded-md border border-gray-300 bg-white text-gray-600 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="new-password"
                      className="block text-sm font-medium leading-6 text-gray-600"
                    >
                      New password
                    </label>
                    <div className="mt-2">
                      <input
                        id="new-password"
                        name="new_password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="********"
                        type="password"
                        autoComplete="new-password"
                        className="px-3 py-2 block w-full rounded-md border border-gray-300 bg-white text-gray-600 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium leading-6 text-gray-600"
                    >
                      Confirm password
                    </label>
                    <div className="mt-2">
                      <input
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        id="confirm-password"
                        name="confirm_password"
                        placeholder="********"
                        type="password"
                        autoComplete="new-password"
                        className="px-3 py-2 block w-full rounded-md border border-gray-300 bg-white text-gray-600 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                {changePasswordErr && changePasswordErr !== "" ? (
                  <p className="mt-8 leading-6 text-red-600 drop-shadow-sm">
                    {changePasswordErr}
                  </p>
                ) : null}

                {changePasswordSuccess && changePasswordSuccess !== "" ? (
                  <p className="mt-8 leading-6 text-green-600 drop-shadow-sm">
                    {changePasswordSuccess}
                  </p>
                ) : null}

                {changePasswordLoading ? (
                  <Loading
                    customClasses={
                      "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6 mt-8"
                    }
                    center={false}
                  />
                ) : null}

                <div className="mt-8 flex">
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-700">
                  Log out of all sessions
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Please enter your password to confirm you would like to log
                  out of all your sessions across all of your devices.
                </p>
              </div>

              <form
                className="md:col-span-2"
                onSubmit={handleLogoutAllSessions}
              >
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                  <div className="col-span-full">
                    <label
                      htmlFor="logout-password"
                      className="block text-sm font-medium leading-6 text-gray-600"
                    >
                      Your password
                    </label>
                    <div className="mt-2">
                      <input
                        value={logoutCurrentPass}
                        onChange={(e) => setLogoutCurrentPass(e.target.value)}
                        id="logout-password"
                        name="logout-password"
                        type="password"
                        placeholder="********"
                        autoComplete="current-password"
                        className="px-3 py-2 block w-full rounded-md border border-gray-300 bg-white text-gray-600 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                {logoutAllErr && logoutAllErr !== "" ? (
                  <p className="mt-8 leading-6 text-red-600 drop-shadow-sm">
                    {logoutAllErr}
                  </p>
                ) : null}

                {logoutAllSuccess && logoutAllSuccess !== "" ? (
                  <p className="mt-8 leading-6 text-green-600 drop-shadow-sm">
                    {logoutAllSuccess}
                  </p>
                ) : null}

                {logoutAllLoading ? (
                  <Loading
                    customClasses={
                      "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6 mt-8"
                    }
                    center={false}
                  />
                ) : null}

                <div className="mt-8 flex">
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Log out all sessions
                  </button>
                </div>
              </form>
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-700">
                  Delete account
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  No longer want to use our service? You can delete your account
                  here. This action is not reversible. All information related
                  to this account will be deleted permanently.
                </p>
              </div>

              {deleteAccountLoading ? (
                <Loading
                  customClasses={
                    "grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6 mt-8"
                  }
                  center={false}
                />
              ) : (
                <div className="flex items-center md:col-span-2 text-center gap-x-8">
                  <button
                    type="button"
                    onClick={handleDeleteAccount}
                    className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                  >
                    Yes, delete my account
                  </button>

                  {deleteAccountErr && deleteAccountErr !== "" ? (
                    <p className="leading-6 text-red-600 drop-shadow-sm">
                      {deleteAccountErr}
                    </p>
                  ) : null}

                  {deleteAccountSuccess && deleteAccountSuccess !== "" ? (
                    <p className="leading-6 text-green-600 drop-shadow-sm">
                      {deleteAccountSuccess}
                    </p>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
