export default function Disclaimer() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">
          Legal
        </p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Disclaimer
        </h1>
        <div className="mt-10 max-w-2xl">
          <p>
            General. You understand and acknowledge that there is a very high
            degree of risk involved in trading securities and, in particular, in
            trading futures and options, and in trading penny stocks.
          </p>
          <p className="mt-8">
            THE SERVICE IS PROVIDED "AS IS." WE AND OUR AFFILIATES AND LICENSORS
            MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, STATUTORY OR OTHERWISE REGARDING THE SERVICE OR THE THIRD
            PARTY CONTENT, INCLUDING ANY WARRANTY THAT THE SERVICE OR THIRD
            PARTY CONTENT WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF HARMFUL
            COMPONENTS, OR THAT ANY CONTENT, INCLUDING YOUR CONTENT OR THE THIRD
            PARTY CONTENT, WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED.
            EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES AND
            LICENSORS DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES
            OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR
            PURPOSE, NON- INFRINGEMENT, OR QUIET ENJOYMENT, AND ANY WARRANTIES
            ARISING OUT OF ANY COURSE OF DEALING OR USAGE OF TRADE.
          </p>
          <p className="mt-8">
            Financial and Other: NEITHER EquityTraderX.com, INC., INDIVIDUALLY,
            NOR ITS WEBSITES INCLUDING, BUT NOT LIMITED TO EquityTraderX.com,
            INC., SITE, NOR ANY OF ITS OFFICERS, EMPLOYEES, REPRESENTATIVES,
            AGENTS, INDEPENDENT CONTRACTORS, OR SUBCONTRACTORS ARE, IN SUCH
            CAPACITIES, LICENSED FINANCIAL ADVISORS, REGISTERED INVESTMENT
            ADVISORS, OR REGISTERED BROKER-DEALERS UNLESS OTHERWISE EXPRESSLY
            PROVIDED. EquityTraderX.com IS NOT Intended as securities brokerage,
            investment, tax, accounting or legal advice.
          </p>
          <p className="mt-8">
            EquityTraderX.com, INC., MAKES NO REPRESENTATIONS OR WARRANTIES
            ABOUT THE ACCURACY OR COMPLETENESS OF THE INFORMATION PROVIDED IN
            CONNECTION WITH THE SERVICE. NO INFORMATION PROVIDED BY
            EquityTraderX.com, INC., IS INTENDED AS SECURITIES BROKERAGE,
            INVESTMENT, TAX, ACCOUNTING OR LEGAL ADVICE, AS AN OFFER OR
            SOLICITATION OF AN OFFER TO SELL OR BUY, OR AS AN ENDORSEMENT,
            RECOMMENDATION OR SPONSORSHIP OF ANY COMPANY, SECURITY OR FUND OR AS
            ADVICE TAILORED TO YOUR OR ANY MEMBER'S PARTICULAR SITUATION. ANY
            URL LINKS OR COMPANY NAMES OR TICKER SYMBOLS ARE OFFERED AS A MATTER
            OF CONVENIENCE AND NOTHING CONTAINED IN THIS AGREEMENT OR IN THE
            SERVICE CONSTITUTES A SOLICITATION, RECOMMENDATION, PROMOTION,
            ENDORSEMENT, OR OFFER BY EquityTraderX.com, INC., OF ANY PARTICULAR
            SECURITY, TRANSACTION, OR INVESTMENT.
          </p>
          <p className="mt-8">
            EquityTraderX.com, INC., MAY HAVE POSITIONS IN THE SECURITIES
            MENTIONED AND MAY ENTER AND EXIT SUCH POSITIONS AT ANY TIME AND FOR
            ANY REASON. THE RISK OF LOSS IN TRADING SECURITIES, OPTIONS,
            FUTURES, FOREX, AND COMMODITIES CAN BE SUBSTANTIAL AND ARE NOT
            SUITABLE FOR ALL INVESTORS. YOU MUST CONSIDER ALL RELEVANT RISK
            FACTORS, INCLUDING YOUR OWN PERSONAL FINANCIAL SITUATION, BEFORE
            TRADING. YOU ASSUME THE RISK OF ANY AND ALL FINANCIAL INVESTMENTS
            YOU MAKE. EquityTraderX.com, INC., IS NOT RESPONSIBLE FOR ANY
            FINANCIAL INVESTMENTS YOU MAKE. RESULTS VARY AND PAST PERFORMANCE IS
            NOT INDICATIVE OF FUTURE RETURNS.
          </p>
          <p className="mt-8">Day–Trading Risk Disclosure Statement:</p>
          <p className="mt-8">
            You should consider the following points before engaging in a
            day-trading strategy. For purposes of this notice, a "day-trading
            strategy" means an overall trading strategy characterized by the
            regular transmission by a customer of intra-day orders to effect
            both purchase and sale transactions in the same security or
            securities.
          </p>
          <p className="mt-8">
            Day trading can be extremely risky. Day trading generally is not
            appropriate for someone of limited resources and limited investment
            or trading experience and low risk tolerance. You should be prepared
            to lose all of the funds that you use for day trading. In
            particular, you should not fund day-trading activities with
            retirement savings, student loans, second mortgages, emergency
            funds, funds set aside for purposes such as education or home
            ownership, or funds required to meet your living expenses.
          </p>
          <p className="mt-8">
            Be cautious of claims of large profits from day trading. You should
            be wary of advertisements or other statements that emphasize the
            potential for large profits in day trading. Day trading can also
            lead to large and immediate financial losses.
          </p>
          <p className="mt-8">
            Day trading requires knowledge of securities markets. Day trading
            requires in-depth knowledge of the securities markets and trading
            techniques and strategies. In attempting to profit through day
            trading, you must compete with professional, licensed traders
            employed by securities firms. You should have appropriate experience
            before engaging in day trading.
          </p>
          <p className="mt-8">
            Day trading requires knowledge of a broker-dealer firm's operations.
            You should be familiar with your broker-dealer firm's business
            practices, including the operation of the firm's order execution
            systems and procedures. Under certain market conditions, you may
            find it difficult or impossible to liquidate a position quickly at a
            reasonable price. This can occur, for example, when the market for a
            stock suddenly drops, or if trading is halted due to recent news
            events or unusual trading activity. The more volatile a stock is,
            the greater the likelihood that problems may be encountered in
            executing a transaction. In addition to normal market risks, you may
            experience losses due to system failures.
          </p>
          <p className="mt-8">
            Day trading will generate substantial commissions, even if the per
            trade cost is low. Day trading involves aggressive and frequent
            trading, and generally you will pay commissions on each trade.
          </p>
          <p className="mt-8">
            The total daily commissions that you pay on your trades will add to
            your losses or significantly reduce your earnings. For instance,
            assuming that a trade costs $16 and an average of 29 transactions
            are conducted per day, an investor would need to generate an annual
            profit of $111,360 just to cover commission expenses. You are
            responsible for understanding the commissions charged by your
            broker-dealer firm and the impact those commissions may have on your
            ability to day trade successfully.
          </p>
          <p className="mt-8">
            Day trading on margin or short selling may result in losses beyond
            your initial investment. When you day trade with funds borrowed from
            a firm or someone else, you can lose more than the funds you
            originally placed at risk. A decline in the value of the securities
            that are purchased may require you to provide additional funds to
            the firm to avoid the forced sale of those securities or other
            securities in your account. Short selling as part of your
            day-trading strategy also may lead to extraordinary losses, because
            you may have to purchase a stock at a very high price in order to
            cover a short position.
          </p>
          <p className="mt-8">
            Potential Registration Requirements. Persons providing investment
            advice for others or managing securities accounts for others may
            need to register as either an "Investment Adviser" under the
            Investment Advisers Act of 1940 or as a "Broker" or "Dealer" under
            the Securities Exchange Act of 1934. Such activities may also
            trigger state registration requirements.
          </p>
        </div>
      </div>
    </div>
  );
}
