import React, { useEffect, useRef } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import sanitizeHtml from "sanitize-html";

function parseStyleString(styleString) {
  const styles = {};
  // Split the string by semicolons to get individual style declarations
  styleString.split(";").forEach((style) => {
    if (style) {
      // Split each declaration into property and value
      const [property, value] = style.split(":").map((item) => item.trim());
      if (property && value) {
        // Convert the CSS property name to camelCase
        const camelCaseProperty = property.replace(/-([a-z])/g, (g) =>
          g[1].toUpperCase()
        );
        // Add the property-value pair to the styles object
        styles[camelCaseProperty] = value;
      }
    }
  });
  return styles;
}

const CustomHtml = ({ html }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = html;

      const scripts = Array.from(
        containerRef.current.querySelectorAll("script")
      );
      scripts.forEach((originalScript) => {
        const script = document.createElement("script");
        script.text = originalScript.innerHTML;

        Array.from(originalScript.attributes).forEach((attr) => {
          script.setAttribute(attr.name, attr.value);
        });

        originalScript.parentNode.replaceChild(script, originalScript);
      });
    }
  }, [html]);

  return <div className="html-embed" ref={containerRef} />;
};

const RichTextRenderer = ({ richText }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // Accessing the fields of the embedded asset
        const { file, title, description } = node.data.target.fields;

        // Return an image element for the asset
        return (
          <img
            className="rounded-md shadow-md"
            src={`https:${file.url}`}
            alt={description || title}
          />
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const contentType = node.data.target.sys.contentType.sys.id;

        if (contentType == "iframe") {
          // Example: Extract iframe details from the Contentful entry
          const {
            heightString,
            widthString,
            url,
            style,
            referrerPolicy,
            title,
          } = node.data.target.fields;

          // Return an iframe element
          return (
            <iframe
              title={title || "Embedded Content"} // Fallback title if none is provided
              src={url}
              style={parseStyleString(style)}
              referrerPolicy={referrerPolicy}
              width={widthString}
              height={heightString}
              allowFullScreen
            ></iframe>
          );
        } else if (contentType == "customHtml") {
          const { code } = node.data.target.fields;

          const safeHtml = sanitizeHtml(code, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat([
              "img",
              "iframe",
              "script",
            ]),
            allowedAttributes: {
              "*": ["class", "id", "style"],
              iframe: [
                "src",
                "width",
                "height",
                "allow",
                "frameborder",
                "allowfullscreen",
              ],
              img: ["src", "alt"],
              script: ["src", "async"],
            },
            allowedSchemes: ["https", "http", "data"],
            allowVulnerableTags: true,
          });

          return <CustomHtml html={safeHtml} />;
        } else {
          return null;
        }
      },
    },
  };

  return (
    <div className="[&>a]:text-indigo-600 [&>p]:mb-8 [&>h2]:font-extrabold contentful-rich-text">
      {documentToReactComponents(richText, options)}
    </div>
  );
};

export default RichTextRenderer;
