import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { client } from "./../components/ContentfulClient";
import Loading from "./../components/Loading";
import { formatDate } from "./../components/utils";

export default function DebriefingPost() {
  const { debriefingId } = useParams();
  const [debriefing, setDebriefing] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    client
      .getEntry(debriefingId)
      .then((entry) => {
        setDebriefing(entry.fields);
        setIsLoading(false);
      })
      .catch(console.error);
  }, [debriefingId]);

  if (isLoading) {
    return <Loading center={true} />;
  }

  return (
    <div className="bg-gray-100 mx-auto max-w-7xl">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {debriefing.title}
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            {debriefing.shortDescription}
          </p>
        </div>
      </div>

      <div className="rounded-md flex items-center flex-col">
        <video
          width="100%"
          height="auto"
          controls
          autoPlay
          muted
          loop
          controlsList="nodownload"
          className="mx-auto rounded-md"
        >
          <source
            src={debriefing.debriefing.fields.file.url}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="pt-4 italic">
          <p>Daily Debriefing posted on {formatDate(debriefing.postDate)}</p>
        </div>
      </div>
    </div>
  );
}
