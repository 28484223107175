import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { client } from "./../components/ContentfulClient";
import RichTextRenderer from "./../components/RichTextRenderer";
import Loading from "./../components/Loading";

export default function TopStockPost() {
  const { stockId } = useParams();
  const [stockPost, setStockPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    client
      .getEntry(stockId)
      .then((post) => {
        setStockPost(post.fields);
        setIsLoading(false);
      })
      .catch(console.error);
  }, [stockId]);

  if (isLoading) {
    return <Loading center={true} />;
  }

  return (
    <div className="mx-auto max-w-7xl">
      <a
        href={`/top-stocks`}
        className="hover:text-indigo800 hover:underline text-base font-semibold leading-7 text-indigo-600"
      >
        {stockPost.marketCategory.fields.marketCategory}
      </a>
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {stockPost.companyName}
      </h1>
      <p className="py-4">
        <span className="text-gray-500 uppercase">
          {stockPost.tickerSymbol}
        </span>
      </p>
      <RichTextRenderer richText={stockPost.body} />
    </div>
  );
}
