import { StockMarket } from "react-ts-tradingview-widgets";

export default function Features() {
  return (
    <div className="relative  text-white mx-auto max-w-7xl px-6 lg:px-8">
      <div className="rounded-3xl black-gradient-left-to-right w-full flex flex-col md:flex-row px-8 mb-8">
        <div className="flex flex-col w-full md:w-1/2 items-center md:items-start drop-shadow-md p-0 md:pl-14">
          <h2 className="text-4xl pt-8 md:pt-24 pb-4">Stock Search</h2>
          <p className="text-xl text-center md:text-left">
            Search any stock, on every <br /> exchange and get real time data.
          </p>
          <div className="flex items-center justify-end mt-8">
            <a
              href="/login?next=search-stocks"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Search Stocks now <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <img
            alt="screenshot of search stocks"
            className="rounded-3xl"
            src="/marketing/search-stocks-sm.png"
          />
        </div>
      </div>
      <div className="flex w-full flex-col md:flex-row">
        <div className="rounded-3xl black-gradient-left-to-right flex flex-col px-8 mb-8 w-full md:w-2/3 mr-6 pb-4 md:pb-0">
          <h2 className="text-4xl pt-8 pb-4 pl-0 md:pl-4 text-center md:text-left">
            Daily Alerts & Debriefings
          </h2>
          <div className="flex flex-col md:flex-row">
            {/* normal */}
            <div className="hidden md:flex flex-col w-1/2 items-start drop-shadow-md pl-0 md:pl-2">
              <p className="text-xl ml-2">
                Get daily alerts and watch debriefings on top stock profiles and
                movements.
              </p>
              <div className="flex items-center justify-end mt-8 ml-2">
                <a
                  href="/signup"
                  className="rounded-md bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up for alerts <span aria-hidden="true">→</span>
                </a>
              </div>
              <img
                alt="screenshot of search stocks"
                className="rounded-3xl"
                src="/marketing/text-alert-sm.png"
              />
            </div>
            <div
              className="hidden md:block w-1/2 border-4 border-indigo-600 ml-6 rounded-lg"
              style={{ height: "338px" }}
            >
              <StockMarket
                colorTheme="dark"
                height={330}
                width="100%"
              ></StockMarket>
            </div>
            {/* mobile */}
            <div className="flex md:hidden flex-col w-full items-start drop-shadow-md pl-0 md:pl-2">
              <p className="text-xl text-center ml-2">
                Get daily alerts and watch debriefings on top stock profiles and
                movements.
              </p>
              <div className="flex items-center justify-center w-full mt-8 ml-2">
                <a
                  href="/signup"
                  className="rounded-md bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up for alerts <span aria-hidden="true">→</span>
                </a>
              </div>
              <img
                alt="screenshot of search stocks"
                className="rounded-3xl"
                src="/marketing/text-alert-sm.png"
              />
            </div>
            <div
              className="block md:hidden w-full border-4 border-indigo-600 rounded-lg"
              style={{ height: "338px" }}
            >
              <StockMarket
                colorTheme="dark"
                height={330}
                width="100%"
              ></StockMarket>
            </div>
          </div>
        </div>
        {/* rounded-3xl black-gradient-left-to-right flex flex-col pl-10 md:pl-6 pr-2 mb-8 w-full md:w-1/3 mr-6 pb-4 md:pb-0 */}
        <div className="rounded-3xl black-gradient-left-to-right flex flex-col px-8 md:pl-6 md:pr-2 mb-8 w-full md:w-1/3 mr-6 pb-4 md:pb-0">
          <h2 className="text-4xl pt-8 pb-4 text-center md:text-left">
            Pro Trading Webinars & Blogs
          </h2>
          <div className="flex flex-col md:flex-row">
            {/* normal */}
            <div className="hidden md:flex flex-col w-1/2 items-start drop-shadow-md">
              <p className="text-xl ml-2">
                Learn pro tricks, new strategies and best trading behaviors
              </p>
              <div className="flex items-center justify-end mt-8 ml-2">
                <a
                  href="/signup"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up for ETX <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="hidden md:block w-1/3 md:w-1/2 pt-0 md:pt-8 ml-2">
              <img
                alt="screenshot of blog"
                className="rounded-3xl"
                src="/marketing/iphone-graphic-sm.png"
              />
            </div>
            {/* mobile */}
            <div className="flex md:hidden flex-col w-full items-start drop-shadow-md">
              <p className="text-xl ml-2 text-center md:text-left">
                Learn pro tricks, new strategies and best trading behaviors
              </p>
              <div className="flex items-center w-full justify-center mt-8 ml-2">
                <a
                  href="/signup"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up for ETX <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="block md:hidden w-full md:w-1/2 pt-0 md:pt-8 ml-2 mt-8">
              <img
                alt="screenshot of blog"
                className="rounded-3xl"
                src="/marketing/iphone-graphic-sm.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col md:flex-row">
        <div className="rounded-3xl black-gradient-left-to-right flex flex-row px-8 mb-8 w-full md:w-1/3 mr-6">
          <div className="flex flex-col w-full items-start drop-shadow-md md:pt-8 md:pb-0 py-8">
            <h2 className="text-4xl pb-4 text-center md:text-left">
              Discord Chat Room Access
            </h2>
            <p className="text-xl text-center md:text-left">
              Connect with a community of traders where you can share
              strategies, get advice, and stay informed about market trends.
            </p>
            <div className="flex items-center justify-center md:justify-start mt-8 w-full">
              <a
                href="/signup"
                className="rounded-md bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign up for ETX <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div className="rounded-3xl black-gradient-left-to-right flex flex-col px-8 mb-8 w-full md:w-2/3 mr-6 py-8 md:py-0">
          <h2 className="text-4xl pt-0 md:pt-8 pb-4 text-center md:text-left">
            News & Press Releases
          </h2>
          <div className="flex flex-col md:flex-row">
            {/* desktop */}
            <div className="hidden md:flex flex-col w-1/2 items-start drop-shadow-md">
              <p className="text-xl ml-2">
                Real-time news and press releases to keep up to date on what's
                happening in the market.
              </p>
              <div className="flex items-center justify-end mt-8 ml-2">
                <a
                  href="/signup"
                  className="rounded-md bg-indigo-600 px-5 md:px-14 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up for ETX <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="hidden md:block w-1/2">
              <img
                alt="screenshot of blog"
                className="rounded-3xl"
                src="/marketing/news-laptop-sm.png"
              />
            </div>
            {/* mobile */}
            <div className="flex md:hidden flex-col w-full items-start drop-shadow-md">
              <p className="text-xl text-center ml-2">
                Real-time news and press releases to keep up to date on what's
                happening in the market.
              </p>
              <div className="flex items-center justify-center w-full mt-8 ml-2">
                <a
                  href="/signup"
                  className="rounded-md bg-indigo-600 px-5 md:px-14 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up for ETX <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <div className="block md:hidden w-full mt-6">
              <img
                alt="screenshot of blog"
                className="rounded-3xl"
                src="/marketing/news-laptop-sm.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
