import React from "react";

const Card = ({ title, text, icon }) => {
  return (
    <div className="card bg-gray-900 p-2">
      <div className="icon">
        <i className="material-icons md-36">{icon}</i>
      </div>
      <p className="title">{title}</p>
      <p className="text">{text}</p>
    </div>
  );
};

export default Card;
