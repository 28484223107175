// useFetchUser.js
const fetchUser = async (dispatch) => {
  dispatch({ type: "SET_LOADING", payload: true }); // Start loading
  try {
    const response = await fetch("/current-user");
    const data = await response.json();
    if (response.ok) {
      dispatch({ type: "SET_USER", payload: data.user });
    } else {
      dispatch({ type: "LOGOUT" });
    }
  } catch (error) {
    dispatch({ type: "LOGOUT" });
  }
};

export default fetchUser;
