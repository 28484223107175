import { CheckIcon } from "@heroicons/react/20/solid";
import Testimonials from "./../Testimonials";
import { loadStripe } from "@stripe/stripe-js";
import { useCSRFToken } from "./../CSRFTokenContext";
import { useState } from "react";
import Loading from "./../components/Loading";

const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

// Publishable Key
const stripePromise = loadStripe(
  isLocalhost
    ? "pk_test_51On9CUFO3casUQvpQ9CGZZ8jrIyBei1oFTTZqNxrCcBpDbpIg1IAucYFJLDjsnRgL0nqHkrqgNO9T0bvgyBfyLrs00RpqKY5l6"
    : "pk_live_51On9CUFO3casUQvp8ODm5uyDZFRkpMcT5Ip4DHPykS2DySe0UaCao81ooTlp4xwlVwGdXlQXkZFOghiGqJVD2p7800TXTnNTz2"
);

const tiers = [
  {
    name: "Monthly",
    id: "monthly",
    priceMonthly: "$50",
    description:
      "Unlock full access to exclusive insights and tools designed to enhance your trading strategy and performance. Charged monthly.",
    features: [
      "Discord Community Access (Full)",
      "Daily SMS Alerts on Top Stocks",
      "Webinars",
      "Blog",
      "Daily Debriefings (First Access to Top Stocks)",
      "News",
      "Search Stocks",
    ],
  },
  {
    name: "Annual",
    id: "annual",
    priceMonthly: "$41",
    description:
      "Unlock full access to exclusive insights and tools designed to enhance your trading strategy and performance. Charged annually.",
    features: [
      "Discord Community Access (Full)",
      "Daily SMS Alerts on Top Stocks",
      "Webinars",
      "Blog",
      "Daily Debriefings (First Access to Top Stocks)",
      "News",
      "Search Stocks",
    ],
  },
];

export default function Subscribe() {
  const { csrfToken } = useCSRFToken();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (billingInterval) => {
    setIsLoading(true);
    const stripe = await stripePromise;
    try {
      // Create a session on your backend
      const response = await fetch("/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ billingInterval }),
      });

      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      setIsLoading(false);

      if (result.error) {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  return isLoading ? (
    <div className="lg:pl-72">
      <Loading />
    </div>
  ) : (
    <div className="lg:pl-72 isolate overflow-hidden bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Choose Your Path to Market Mastery
          </p>
        </div>
        <div className="relative mt-6">
          <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
            Empower your journey in market mastery with our subscription
            options.
          </p>
          <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
            <ellipse
              cx={604}
              cy={512}
              fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)"
              rx={604}
              ry={512}
            />
            <defs>
              <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 mb-10">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 text-indigo-600"
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        {tier.priceMonthly}
                      </span>
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        /month
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button
                    className="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => handleClick(tier.id)}
                  >
                    Get started today
                  </button>
                </div>
              ))}
            </div>
            <Testimonials />
          </div>
        </div>
      </div>
    </div>
  );
}
