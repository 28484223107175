import React from "react";
import { useCSRFToken } from "./../CSRFTokenContext";

const VerifyBanner = () => {
  const { csrfToken } = useCSRFToken();

  const resendVerificationEmail = () => {
    fetch("/api/resend-email-verification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": csrfToken,
      },
    })
      .then((response) => {
        if (response.statusText === "Too Many Requests") {
          return response.json();
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        alert(data.message);
      })
      .catch((error) => {
        console.error("Error during fetch operation:", error);
        alert("Error resending verification email.");
      });
  };

  return (
    <div className="fixed lg:top-0 top-14 z-30 w-full bg-indigo-600 px-6 py-2.5 sm:px-3.5 lg:pr-72">
      <p className="text-sm leading-6 text-white flex items-center justify-center">
        <strong className="font-semibold">Email Not Verified</strong>
        <svg
          viewBox="0 0 2 2"
          className="mx-2 inline h-0.5 w-0.5 fill-current"
          aria-hidden="true"
        >
          <circle cx={1} cy={1} r={1} />
        </svg>
        <span
          className="hover:underline hover:cursor-pointer"
          onClick={resendVerificationEmail}
        >
          Send verification link to your email address
          <span className="pl-2" aria-hidden="true">
            &rarr;
          </span>
        </span>
      </p>
    </div>
  );
};

export default VerifyBanner;
