import Card from "./components/Card";
import ContactUs from "./ContactUs";

export default function PublicCompanies() {
  return (
    <div>
      <div className="bg-white pt-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
              <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src="/investor-connection.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-indigo-600">
                  Amplifying Investor Reach
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Helping public companies effectively reach investors
                </h1>
                <div className="max-w-xl">
                  <p className="mt-6">
                    Equity Trader X, an eminent digital marketing leader
                    specializing in public markets, has masterfully administered
                    marketing budgets exceeding $75 million USD. Our proficiency
                    extends to engaging retail investors through a sophisticated
                    amalgamation of proprietary advertising networks, highly
                    effective landing pages, tailored ticker tag articles,
                    public relations, and a unique influencer network across
                    diverse social media platforms. Additionally, we leverage
                    the power of chat rooms, SMS, and newsletter marketing,
                    complemented by impactful press releases, to broaden our
                    outreach and deepen engagement. The proven success of Equity
                    Trader X stands as a testament to our dynamic marketing
                    force, consistently achieving exceptional outcomes for our
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative isolate overflow-hidden bg-white pt-24 sm:pt-32 lg:overflow-visible px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 px-6 lg:px-8">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-indigo-600">
                  Revolutionizing Investment Engagement
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Harness the power of digital marketing
                </h1>
                <blockquote className="p-4 mb-4 mt-8 border-s-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">
                  <p className="text-xl italic font-medium leading-relaxed text-gray-900 dark:text-white">
                    "The emergence of the meme stock phenomenon during this era
                    has emphatically highlighted the{" "}
                    <b>substantial impact that digital marketing</b> can exert
                    on the financial markets."
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="hidden md:block -ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              src="/sleek_office.png"
              alt=""
            />
          </div>

          <div className="md:hidden lg:pr-4">
            <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="/sleek_office.png"
                alt=""
              />
            </div>
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <p>
                  In the wake of recent developments, it has become increasingly
                  apparent that digital marketing represents a swift and
                  cost-efficient strategy for generating interest among retail
                  investors. During the challenges presented by the Covid-19
                  pandemic, a multitude of issuers transitioned to digital
                  marketing strategies as an alternative to traditional
                  face-to-face interactions, with this shift resulting in
                  significant positive outcomes.
                </p>
                <p className="mt-8">
                  The emergence of the meme stock phenomenon during this era has
                  emphatically highlighted the substantial impact that digital
                  marketing can exert on the financial markets. This impact has
                  left a lasting mark on hedge funds and financial institutions
                  worldwide, altering their perception and approach to market
                  engagement.
                </p>

                <p className="mt-8">
                  Equity Trader X, with its comprehensive and verified history
                  of success, stands at the forefront of this transformation. We
                  specialize in assisting publicly traded companies in
                  effectively connecting with their target investor
                  demographics. This strategic engagement not only amplifies
                  market presence but also translates into tangible returns on
                  investment, demonstrating our commitment to excellence and
                  client success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative pt-24">
          <div className="max-w-xl mx-auto text-center">
            <div className="inline-flex px-4 py-1.5 mx-auto rounded-full  ">
              <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Services Provided
              </p>
            </div>
            <p className="mt-4 text-base leading-relaxed text-gray-600 group-hover:text-white">
              We provide a full suite of next generation digital marketing
              solutions and utilize our online trading community platform to
              help your company effectively reach investors and push your
              company narrative to the masses.
            </p>
          </div>
        </div>

        <div className="w-screen bg-gray-800 mt-6">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative py-8">
            <div className="content grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 items-center justify-center gap-2">
              <Card
                icon="insert_chart_outlined"
                title="Digital & Display Ads"
                text="Full Scale Digital and Display Traffic Ads"
              />
              <Card
                icon="group_add"
                title="Influencer Networks"
                text="Customized Influencer Networks"
              />
              <Card
                icon="receipt_long"
                title="Ticker Articles"
                text="Ticker Tag Articles"
              />
              <Card
                icon="redo"
                title="Audience Retargeting"
                text="Retargeting Audiences"
              />
              <Card
                icon="notifications_active"
                title="Messaging Lists"
                text="Exclusive Email, SMS, and Push Notification Lists"
              />
              <Card
                icon="trending_up"
                title="Investor Media"
                text="Investor Relations Media"
              />
              <Card
                icon="create"
                title="Narrative Strategy"
                text="Narrative Development and Strategy"
              />
              <Card
                icon="share"
                title="Social Media"
                text="Social Media Management"
              />
              <Card
                icon="call_received"
                title="Inbound Relations"
                text="Inbound IR"
              />
              <Card
                icon="campaign"
                title="Press Releases"
                text="Press Releases"
              />
              <Card
                icon="record_voice_over"
                title="CEO Interviews"
                text="CEO Interviews"
              />
              <Card
                icon="groups"
                title="Trading Community"
                text="Online Trading Community Platform"
              />
              <Card icon="chat" title="Chat Rooms" text="Chat Rooms" />
              <Card
                icon="article"
                title="Featured Articles"
                text="Featured Articles through 500+ News Platforms"
              />
              <Card
                icon="code"
                title="Web Development"
                text="Custom Web Development"
              />
            </div>
          </div>
        </div>
      </div>

      <ContactUs isPublicCompaniesContactForm={true} />
    </div>
  );
}
