import React from "react";
import { getTitleToSlug } from "./utils";

const BlogPostGrid = ({ posts }) => {
  return (
    <div className="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      {posts.map((post) => (
        <article key={post.id} className="flex flex-col items-start">
          <a
            className="relative w-full cursor-pointer hover:brightness-75"
            href={`/blog/posts/${post.id}/${getTitleToSlug(post.title)}`}
          >
            <img
              src={post.imageUrl}
              alt=""
              className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </a>
          <div className="max-w-xl">
            <div className="mt-8 flex items-center gap-x-4 text-xs">
              <time dateTime={post.datetime} className="text-gray-500">
                {post.date}
              </time>
              <a
                href={post.category.href}
                className="cursor-pointer relative z-10 rounded-full bg-indigo-500 px-3 py-1.5 font-medium text-white hover:text-gray-300"
              >
                {post.category.title}
              </a>
            </div>
            <div className="group relative">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-indigo-600">
                <a
                  href={`/blog/posts/${post.id}/${getTitleToSlug(post.title)}`}
                  className="cursor-pointer"
                >
                  <span className="absolute inset-0" />
                  {post.title}
                </a>
              </h3>
              <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                {post.description}
              </p>
            </div>
            <div className="relative mt-8 flex items-center gap-x-4">
              <img
                src={post.author.imageUrl}
                alt=""
                className="h-10 w-10 rounded-full bg-gray-100"
              />
              <div className="text-sm leading-6">
                <p className="font-semibold text-gray-900 hover:text-indigo-500">
                  <a href={post.author.href}>
                    <span className="absolute inset-0" />
                    {post.author.name}
                  </a>
                </p>
                <p className="text-gray-600">{post.author.role}</p>
              </div>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};

export default BlogPostGrid;
