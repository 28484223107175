import React, { useEffect } from "react";
import "./App.css";
import Header from "./Header";
import Hero from "./Hero";
import StockNews from "./StockNews";
import FeaturedStockPicks from "./FeaturedStockPicks";
import PricingPlan from "./PricingPlan";
import WhySubscribe from "./WhySubscribe";
import Features from "./Features";
import Testimonials from "./Testimonials";
import CTA from "./CTA";
import Footer from "./Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import Signup from "./Signup";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import News from "./News";
import NewsPost from "./NewsPost";
import NewsAuthor from "./NewsAuthor";
import Verify from "./Verify";
import PublicCompanies from "./PublicCompanies";
import Disclaimer from "./Disclaimer";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import NotFoundPage from "./NotFoundPage";
import UnderConstruction from "./UnderConstruction";
import NotPremiumPage from "./app/NotPremiumPage";
import fetchUser from "./useFetchUser";
import { useUser } from "./UserContext";
import Subscribe from "./app/Subscribe";
import Dashboard from "./app/Dashboard";
import Success from "./app/Success";
import Blog from "./app/BlogPage";
import Settings from "./app/Settings";
import BlogPost from "./app/BlogPost";
import TopStockPost from "./app/TopStockPost";
import WebinarPost from "./app/WebinarPost";
import Webinars from "./app/Webinars";
import TopStocks from "./app/TopStocks";
import Loading from "./components/Loading";
import PremiumRoute from "./components/PremiumRoute";
import VerifyBanner from "./components/VerifyBanner";
import AuthorPage from "./app/AuthorPage";
import CategoryPage from "./app/CategoryPage";
import SearchStocks from "./app/SearchStocks";
import ChatRoom from "./app/ChatRoom";
import Screener from "./app/Screener";

import AppNavigation from "./app/App_Navigation";
import DailyDebriefings from "./app/DailyDebriefings";
import DailyDebriefingPost from "./app/DailyDebriefingPost";

function MarketingWebsite() {
  return (
    <div>
      <Header />
      {/* Define your routes within the Routes component */}
      <Routes>
        {/* Home Page: include components that should only appear on the home page */}
        <Route path="/verify" element={<Verify isInApp={false} />} />
        <Route
          path="/"
          element={
            <>
              <Hero />
              <StockNews />
              <FeaturedStockPicks />
              <Features />
              <PricingPlan />
              <WhySubscribe />
              <Testimonials />
              <CTA />
            </>
          }
        />

        <Route path="/news" element={<News />} />
        <Route path="/news/:newsId/:newsTitle" element={<NewsPost />} />
        <Route path="/news/author/:authorId" element={<NewsAuthor />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/public-companies" element={<PublicCompanies />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password/reset" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ForgotPasswordConfirm />} />
        <Route path="/signup" element={<Signup />} />
        {/* Add more routes as needed */}
        {/* <Route path="/somepath" element={<SomeComponent />} /> */}

        {/* Fallback route, if no other routes match (404 Page) */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

function LoggedInApp({ user }) {
  let addPaddingIfNotVerified = user.email_verified ? "" : "pt-20 lg:pt-10";
  let addExtraPaddingIfNotVerified = user.email_verified
    ? ""
    : "pt-32 lg:pt-16";

  let addExtraPaddingForSettingsIfNotVerified = user.email_verified
    ? ""
    : "pt-12";
  return (
    <div>
      <AppNavigation />
      <Routes>
        <Route
          path="/disclaimer"
          element={
            <main className="lg:pl-72">
              <div>
                <Disclaimer />
              </div>
            </main>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <main className="lg:pl-72">
              <div>
                <TermsOfService />
              </div>
            </main>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <main className="lg:pl-72">
              <div>
                <PrivacyPolicy />
              </div>
            </main>
          }
        />

        <Route
          path="/"
          element={
            <main className="lg:pl-72">
              <div className="">
                {user.email_verified ? null : <VerifyBanner />}
                <div className={addPaddingIfNotVerified}>
                  <Dashboard />
                </div>
              </div>
            </main>
          }
        />
        <Route
          path="/verify"
          element={
            <main className="py-10 lg:pl-72 bg-gray-100 h-screen">
              <div className="px-4 sm:px-6 lg:px-8">
                <Verify isInApp={true} />
              </div>
            </main>
          }
        />
        {/* If user is already subscribed forward to main dashboard */}
        <Route path="/subscribe" element={<Subscribe />} />
        {/* Don't let user navigate here by themselves */}
        <Route
          path="/success"
          element={
            <main className="py-10 lg:pl-72 bg-gray-100 h-screen">
              <div className="px-4 sm:px-6 lg:px-8">
                <Success />
              </div>
            </main>
          }
        />
        <Route
          path="/contact-us"
          element={
            <main className="lg:pl-72 h-screen">
              {user.email_verified ? null : <VerifyBanner />}
              <div className="py-10 px-4 sm:px-6 lg:px-8">
                <ContactUs />
              </div>
            </main>
          }
        />
        <Route path="/not-premium" element={<NotPremiumPage />} />

        <Route
          path="/news/author/:authorId"
          element={
            <div className="lg:pl-72 bg-gray-100 h-screen">
              {user.email_verified ? null : <VerifyBanner />}
              <main
                className={`py-20 lg:py-10 bg-gray-100 ${addExtraPaddingIfNotVerified}`}
              >
                <div className="px-4 sm:px-6 lg:px-8">
                  <NewsAuthor backgroundColor="bg-gray-100" />
                </div>
              </main>
            </div>
          }
        />

        <Route
          path="/news/:newsId/:newsTitle"
          element={
            <div className="lg:pl-72 bg-gray-100 h-screen">
              {user.email_verified ? null : <VerifyBanner />}
              <main
                className={`py-20 lg:py-10 bg-gray-100 ${addExtraPaddingIfNotVerified}`}
              >
                <div className="px-4 sm:px-6 lg:px-8">
                  <NewsPost backgroundColor="bg-gray-100" />
                </div>
              </main>
            </div>
          }
        />

        <Route
          path="/news"
          element={
            <div className="lg:pl-72 bg-gray-100 h-screen">
              {user.email_verified ? null : <VerifyBanner />}
              <main
                className={`py-20 lg:py-10 bg-gray-100 ${addExtraPaddingIfNotVerified}`}
              >
                <div className="px-4 sm:px-6 lg:px-8">
                  <News backgroundColor="bg-gray-100" />
                </div>
              </main>
            </div>
          }
        />

        {/* Premium Routes */}
        <Route
          path="/blog"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`lg:py-10 py-20 bg-gray-100 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <Blog />
                  </div>
                </main>
              }
            />
          }
        />
        <Route
          path="/blog/authors/:authorId"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`lg:py-10 py-20 bg-gray-100 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <AuthorPage />
                  </div>
                </main>
              }
            />
          }
        />
        <Route
          path="/blog/categories/:categoryId"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`lg:py-10 py-20 bg-gray-100 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <CategoryPage />
                  </div>
                </main>
              }
            />
          }
        />
        <Route
          path="/blog/posts/:postId/:postTitle"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`py-20 lg:py-10 bg-gray-100 px-12 lg:px-48 ${addExtraPaddingIfNotVerified}`}
                  >
                    <BlogPost />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/webinars"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`lg:py-10 py-20 bg-gray-100 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <Webinars />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/webinars/:webinarId"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`lg:py-10 py-20 bg-gray-100 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <WebinarPost />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/top-stocks"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`py-20 lg:py-10 px-4 bg-gray-100 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <TopStocks />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/top-stocks/:stockId"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`py-20 lg:py-10 bg-gray-100 px-12 lg:px-48 ${addExtraPaddingIfNotVerified}`}
                  >
                    <TopStockPost />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/screener"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    style={{ backgroundColor: "#141721" }}
                    className={`py-20 lg:py-10 px-4  sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <Screener />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/portfolio"
          element={
            <PremiumRoute
              element={
                <main className="py-10 lg:pl-72 bg-gray-100 h-screen">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <UnderConstruction backgroundColor={"bg-gray-100"} />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/daily-debriefings"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`py-20 lg:py-10 px-4 bg-gray-100 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <DailyDebriefings />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/daily-debriefings/:debriefingId"
          element={
            <PremiumRoute
              element={
                <main className="lg:pl-72 bg-gray-100 h-screen">
                  {user.email_verified ? null : <VerifyBanner />}
                  <div
                    className={`lg:py-10 py-20 bg-gray-100 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                  >
                    <DailyDebriefingPost />
                  </div>
                </main>
              }
            />
          }
        />

        <Route
          path="/search-stocks"
          element={
            <div className="lg:pl-72 bg-gray-100 h-screen">
              {user.email_verified ? null : <VerifyBanner />}
              <main>
                <div
                  className={`lg:py-10 py-20 bg-gray-100 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                >
                  <SearchStocks />
                </div>
              </main>
            </div>
          }
        />

        <Route
          path="/chat-room"
          element={
            <div
              style={{ backgroundColor: "#141721" }}
              className="lg:pl-72 h-screen"
            >
              {user.email_verified ? null : <VerifyBanner />}
              <main>
                <div
                  style={{ backgroundColor: "#141721" }}
                  className={`lg:py-10 py-20 px-4 sm:px-6 lg:px-8 ${addExtraPaddingIfNotVerified}`}
                >
                  <ChatRoom />
                </div>
              </main>
            </div>
          }
        />

        <Route
          path="/settings"
          element={
            <div className="lg:pl-72 bg-gray-100 h-screen">
              {user.email_verified ? null : <VerifyBanner />}
              <main>
                <div className={`${addExtraPaddingForSettingsIfNotVerified}`}>
                  <Settings />
                </div>
              </main>
            </div>
          }
        />

        {/* Fallback Route */}
        <Route
          path="*"
          element={
            <main className="py-10 lg:pl-72 bg-gray-100 h-screen">
              <div className="px-4 sm:px-6 lg:px-8">
                <NotFoundPage backgroundColor="bg-gray-100" />
              </div>
            </main>
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  const { state, dispatch } = useUser();
  const user = state.user;

  useEffect(() => {
    fetchUser(dispatch);
  }, [dispatch]);

  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";

  if (isLocalhost) {
    console.log(user);
  }

  if (state.loading) {
    return <Loading />;
  }

  return (
    <Router>
      {user && user.sms_verified ? (
        <LoggedInApp user={user} />
      ) : (
        <MarketingWebsite />
      )}
    </Router>
  );
}

export default App;
