import React from "react";
import {
  SymbolInfo,
  AdvancedRealTimeChart,
  TechnicalAnalysis,
  FundamentalData,
  CompanyProfile,
  Timeline,
  MarketOverview,
  StockMarket,
  MarketData,
} from "react-ts-tradingview-widgets";

import StockTickerPosts from "./StockTickerPosts";

const StockWidgets = ({ incomingSymbol }) => {
  return (
    <div>
      <SymbolInfo autosize symbol={incomingSymbol} />
      <div className="flex md:space-x-6 space-y-6 md:space-y-0 pt-8 w-full flex-col md:flex-row">
        <div className="md:w-2/3 w-full h-96">
          <AdvancedRealTimeChart
            hide_side_toolbar={true}
            width={800}
            symbol={incomingSymbol}
            autosize={true}
          ></AdvancedRealTimeChart>
        </div>
        <div className="md:w-1/3 w-full h-96">
          <TechnicalAnalysis
            symbol={incomingSymbol}
            height={578}
            width="100%"
            autosize={true}
          ></TechnicalAnalysis>
        </div>
      </div>
      <div className="flex md:space-x-6 space-y-6 md:space-y-0 pt-8 w-full flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          <FundamentalData
            symbol={incomingSymbol}
            height={800}
            width="100%"
          ></FundamentalData>
        </div>
        <div className="w-full md:w-1/2">
          <CompanyProfile
            symbol={incomingSymbol}
            height={800}
            width="100%"
          ></CompanyProfile>
        </div>
      </div>
      <StockTickerPosts symbol={incomingSymbol} />
      <div className="pt-8">
        <Timeline
          feedMode="symbol"
          symbol={incomingSymbol}
          height={800}
          width="100%"
        ></Timeline>
      </div>
      <div className="flex md:space-x-6 space-y-6 md:space-y-0 pt-8 w-full flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          <MarketOverview
            height={800}
            width="100%"
            showFloatingTooltip
          ></MarketOverview>
        </div>
        <div className="w-full md:w-1/2">
          <StockMarket height={800} width="100%"></StockMarket>
        </div>
      </div>
      <div className="pt-8">
        <MarketData width="100%" height={800}></MarketData>
      </div>
    </div>
  );
};

export default StockWidgets;
