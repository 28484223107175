import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Loading from "./components/Loading";
import fetchUser from "./useFetchUser";
import { useUser } from "./UserContext";

export default function Verify({ isInApp }) {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);
  const { dispatch, state } = useUser();
  const user = state.user;
  const [verificationMessage, setVerificationMessage] = useState("");

  const getSuccessFromLocalStorage = () => {
    const savedSuccess = localStorage.getItem("success");
    const savedTimestamp = localStorage.getItem("successTimestamp");

    if (savedSuccess && savedTimestamp) {
      const currentTime = new Date().getTime();
      const timeElapsed = (currentTime - parseInt(savedTimestamp)) / 1000 / 60; // Convert to minutes

      if (timeElapsed < 2) {
        // Check if less than 2 minutes have passed
        return JSON.parse(savedSuccess);
      }
    }

    return false;
  };

  const [success, setSuccess] = useState(getSuccessFromLocalStorage());

  useEffect(() => {
    const verifyEmail = async () => {
      const token = query.get("token");
      if (!token) {
        setVerificationMessage("Verification token is missing.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`/verify-email-token?token=${token}`);

        if (response.ok) {
          await fetchUser(dispatch);
          setSuccess(true);
          // Save success state to local storage
          localStorage.setItem("success", "true");
          localStorage.setItem(
            "successTimestamp",
            new Date().getTime().toString()
          );
        }

        setIsLoading(false);
      } catch (error) {
        setVerificationMessage(
          "An error occurred during verification. Please try again later."
        );
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [dispatch, query]);

  useEffect(() => {
    if ((user && user.email_verified) || success) {
      setVerificationMessage("Your email has been successfully verified.");
    }
  }, [user, success]);

  if (isLoading) {
    return <Loading center={true} />;
  }

  return (
    <div className={isInApp ? `bg-gray-100` : `bg-white`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Verify Email Address
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            {verificationMessage}
          </p>
        </div>
      </div>
    </div>
  );
}
