import React, { useState, useEffect } from "react";
import { TickerTape } from "react-ts-tradingview-widgets";
import Loading from "./components/Loading";
import NewsGridItem from "./components/NewsGridItem";
import { getTitleToSlug } from "./components/utils";

function getSmallImageUrl(item) {
  const smallImage = item.image.find((item) => item.size === "small");

  return smallImage ? smallImage.url : "/default-news.png";
}

export default function StockNews() {
  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchNews() {
      try {
        const response = await fetch("/api/news?page=1&limit=6");
        const data = await response.json();
        setIsLoading(false);
        let firstSixItems = data.news.slice(0, 6);

        setNewsItems(firstSixItems);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }
    fetchNews();
  }, []);

  return (
    <div className="container mt-24 mx-auto px-6">
      <section className="mb-12">
        <div className="mx-auto max-w-2xl text-center mb-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Latest Financial News
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Stay ahead with swift updates and insights on pivotal financial
            trends.
          </p>
        </div>
        <TickerTape
          symbols={[
            {
              proName: "FOREXCOM:SPXUSD",
              title: "S&P 500",
            },
            {
              proName: "FOREXCOM:NSXUSD",
              title: "Nasdaq 100",
            },
            {
              proName: "NASDAQ:TSLA",
              title: "Tesla",
            },
            {
              proName: "NASDAQ:META",
              title: "Meta",
            },
            {
              proName: "NASDAQ:NVDA",
              title: "Nvidia",
            },
            {
              proName: "BLACKBULL:US30",
              title: "Dow Jones",
            },
            {
              proName: "FX_IDC:EURUSD",
              title: "EUR/USD",
            },
            {
              proName: "BITSTAMP:BTCUSD",
              title: "BTC/USD",
            },
          ]}
          colorTheme="light"
        ></TickerTape>
        {isLoading ? (
          <Loading center={false} />
        ) : (
          <div className="grid gap-6 lg:grid-cols-3">
            {newsItems.map((item, index) => (
              <NewsGridItem
                key={index}
                imageUrl={
                  item.isETXPost
                    ? item.smallImage.fields.file.url
                    : getSmallImageUrl(item)
                }
                link={`/news/${item.id}/${getTitleToSlug(item.title)}`}
                title={item.title}
                source={item.isETXPost ? item.author.fields.name : item.author}
                date={new Date(
                  item.isETXPost ? item.postDate : item.created
                ).toLocaleDateString()}
                isBig={false}
              />
            ))}
          </div>
        )}
      </section>
    </div>
  );
}
