export default function FeaturedStockPicks() {
  return (
    <section className=" bg-indigo-500ray-50">
      <div className="pt-8 pb-4 block relative bg-opacity-50  z-40">
        <div className="relative mx-auto h-full px-4 pb-20   md:pb-10 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
            <div className="max-w-xl mx-auto text-center">
              <div className="inline-flex px-4 py-1.5 mx-auto rounded-full  ">
                <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Proven Picks Showcase
                </p>
              </div>
              <p className="mt-4 text-base leading-relaxed text-gray-600 group-hover:text-white">
                Dive into our curated selection of recent past winners. Each box
                reveals a success story, highlighting the significant gains
                following our expert alerts. Discover how our insights lead to
                real market triumphs.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-2 mt-12 sm:grid-cols-3">
              <a
                href="/login?next=top-stocks"
                className="transition-all  duration-500 bg-white hover:bg-indigo-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
              >
                <div className=" absolute  bg-indigo-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                <div className="py-2 px-9 relative  ">
                  <svg
                    className="w-16 h-16 fill-gray-400 group-hover:fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    id="polo-tshirt"
                  >
                    <path d="m16.962 5.841 3.582.717-.716-4.115zM12.172 2.443l-.716 4.115 3.582-.717z"></path>
                    <path d="M28.488 7.301a5.354 5.354 0 0 0-2.743-3.305c-.112-.056-2.96-1.2-4.942-1.996l.87 4.987a.52.52 0 0 1-.488.746.535.535 0 0 1-.101-.01L16.5 6.806V12a.5.5 0 1 1-1 0V6.806l-4.583.917a.518.518 0 0 1-.613-.598L11.196 2c-1.982.796-4.828 1.94-4.94 1.996a5.36 5.36 0 0 0-2.75 3.328l-1.492 6.26c-.042.175.01.359.138.486l.608.608a5.487 5.487 0 0 0 3.907 1.618c.196 0 .375-.111.464-.287l1.072-2.145c.013.292.02.584.02.876V28.48c0 .231.153.435.375.499A27.03 27.03 0 0 0 16 30c2.508 0 5.015-.34 7.402-1.022a.52.52 0 0 0 .376-.5V14.74c0-.292.006-.584.019-.876l1.073 2.145a.519.519 0 0 0 .463.287 5.487 5.487 0 0 0 3.907-1.618l.608-.608a.518.518 0 0 0 .138-.487l-1.498-6.282zM17.03 11.5c-.276 0-.505-.224-.505-.5s.219-.5.495-.5h.01a.5.5 0 0 1 0 1zm0-2c-.276 0-.505-.224-.505-.5s.219-.5.495-.5h.01a.5.5 0 0 1 0 1z"></path>
                  </svg>
                  <h3 className="mt-8 text-lg font-semibold text-black group-hover:text-white ">
                    Tech Titan Triumph
                  </h3>
                  <p className="mt-4 text-base text-gray-600 group-hover:text-white  ">
                    Saw a{" "}
                    <span className="text-green-500 font-bold drop-shadow-md">
                      +453%
                    </span>{" "}
                    surge post-alert. Tech stocks leading the charge.
                  </p>
                </div>
              </a>
              <a
                href="/login?next=top-stocks"
                className="transition-all  duration-500 bg-white hover:bg-indigo-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
              >
                <div className=" absolute  bg-indigo-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                <div className="py-2 px-9 relative  ">
                  <svg
                    className="w-16 h-16 group-hover:stroke-white stroke-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.2651 3.70309C9.72917 3.47824 7.38425 4.16153 5.84197 5.4923C5.65153 5.65663 5.82289 5.9364 6.06757 5.87805C7.78468 5.46852 9.73866 5.38359 11.772 5.68067L12.2651 3.70309Z"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.168 6.52739C17.1029 7.21968 18.7883 8.21203 20.1122 9.37977C20.3009 9.54616 20.5835 9.37959 20.4925 9.14509C19.7555 7.246 18.0058 5.5418 15.6611 4.5498L15.168 6.52739Z"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="12.4812"
                      y="5.93628"
                      width="2"
                      height="5"
                      transform="rotate(14 12.4812 5.93628)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="10.7865"
                      y="10.6668"
                      width="3"
                      height="9.5"
                      transform="rotate(14 10.7865 10.6668)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="12.3583"
                      y="3.3291"
                      width="3.5"
                      height="2.75"
                      transform="rotate(14 12.3583 3.3291)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h3 className="mt-8 text-lg font-semibold text-black group-hover:text-white ">
                    Mining Marvel
                  </h3>
                  <p className="mt-4 text-base text-gray-600 group-hover:text-white">
                    Advanced{" "}
                    <span className="text-green-500 drop-shadow-md font-bold">
                      +314%
                    </span>{" "}
                    after our forecast. Pioneering mining growth.
                  </p>
                </div>
              </a>
              <a
                href="/login?next=top-stocks"
                className="transition-all  duration-500 bg-white hover:bg-indigo-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
              >
                <div className=" absolute  bg-indigo-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                <div className="py-2 px-9 relative  ">
                  <svg
                    className="w-16 h-16 fill-gray-400 group-hover:fill-white"
                    version="1.1"
                    id="Capa_1"
                    viewBox="0 0 333.343 333.343"
                  >
                    <path
                      d="M332.219,143.108c-0.028-2.444-1.172-4.741-3.104-6.236l-67.054-51.886c-1.401-1.085-3.123-1.673-4.896-1.673H76.176
	c-1.772,0-3.494,0.588-4.896,1.673L4.227,136.871c-1.933,1.496-3.076,3.792-3.103,6.236L0.001,241.94
	c-0.024,2.137,0.808,4.195,2.311,5.715c1.502,1.52,3.551,2.376,5.688,2.376h317.343c2.138,0,4.186-0.855,5.688-2.376
	c1.503-1.52,2.335-3.578,2.312-5.715L332.219,143.108z M95.774,99.312h40.919l-10.334,35.886H69.531
	c8.516-11.208-5.295-14.188-17.922-14.761l14.154-10.953C78.288,109.609,97.163,108.319,95.774,99.312z M267.579,109.485
	l14.154,10.953c-12.627,0.572-26.436,3.553-17.921,14.761h-56.829l-10.336-35.886h40.922
	C236.181,108.319,255.054,109.609,267.579,109.485z M123.55,151.199v8.711H16.933l0.099-8.711H123.55z M123.55,175.909v9.756H16.64
	l0.111-9.756H123.55z M123.55,201.665v7.164H16.377l0.081-7.164H123.55z M209.792,208.829v-7.164h107.092l0.081,7.164H209.792z
	 M209.792,185.665v-9.756H316.59l0.111,9.756H209.792z M209.792,159.909v-8.711H316.31l0.099,8.711H209.792z M16.196,224.829H123.55
	v9.202H16.091L16.196,224.829z M209.792,234.031v-9.202h107.355l0.104,9.202H209.792z"
                    />
                  </svg>
                  <h3 className="mt-8 text-lg font-semibold text-black group-hover:text-white ">
                    Financial Whiz
                  </h3>
                  <p className="mt-4 text-base text-gray-600 group-hover:text-white">
                    Banked a solid{" "}
                    <span className="text-green-500 drop-shadow-md font-bold">
                      +280%
                    </span>{" "}
                    increase post-analysis. Finance's finest featured.
                  </p>
                </div>
              </a>
              <a
                href="/login?next=top-stocks"
                className="transition-all  duration-500 bg-white hover:bg-indigo-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
              >
                <div className=" absolute  bg-indigo-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                <div className="py-2 px-9 relative  ">
                  <svg
                    className="w-16 h-16 fill-gray-400 group-hover:fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                    id="engine"
                  >
                    <path d="M60,34H56a1,1,0,0,0-1,1v3H52V28a1,1,0,0,0-1-1H47.51l-4.7-6.58A1,1,0,0,0,42,20H35V17h3a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1H26a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h3v3H24a1,1,0,0,0-.81.42L18.49,27H13a1,1,0,0,0-1,1V38H9V35a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1V47a1,1,0,0,0,1,1H8a1,1,0,0,0,1-1V44h3v5a1,1,0,0,0,1,1H24.59l2.7,2.71A1,1,0,0,0,28,53H48a1,1,0,0,0,.71-.29l3-3A1,1,0,0,0,52,49V44h3v3a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V35A1,1,0,0,0,60,34ZM35.77,37.64l-5,6A1,1,0,0,1,30,44a1,1,0,0,1-.64-.23,1,1,0,0,1-.13-1.41L32.87,38H29a1,1,0,0,1-.77-1.64l5-6a1,1,0,0,1,1.54,1.28L31.13,36H35a1,1,0,0,1,.77,1.64Z"></path>
                  </svg>
                  <h3 className="mt-8 text-lg font-semibold text-black group-hover:text-white ">
                    Energy Innovator
                  </h3>
                  <p className="mt-4 text-base text-gray-600 group-hover:text-white">
                    Energized returns of{" "}
                    <span className="text-green-500 drop-shadow-md font-bold">
                      +175%
                    </span>
                    , following our alert. The future is bright.
                  </p>
                </div>
              </a>
              <a
                href="/login?next=top-stocks"
                className="transition-all  duration-500 bg-white hover:bg-indigo-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
              >
                <div className=" absolute  bg-indigo-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                <div className="py-2 px-9 relative  ">
                  <svg
                    className="w-16 h-16 group-hover:stroke-white stroke-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7 6H5.2C4.0799 6 3.51984 6 3.09202 6.21799C2.71569 6.40973 2.40973 6.71569 2.21799 7.09202C2 7.51984 2 8.0799 2 9.2V17.8C2 18.9201 2 19.4802 2.21799 19.908C2.40973 20.2843 2.71569 20.5903 3.09202 20.782C3.51984 21 4.0799 21 5.2 21H18.8C19.9201 21 20.4802 21 20.908 20.782C21.2843 20.5903 21.5903 20.2843 21.782 19.908C22 19.4802 22 18.9201 22 17.8V9.2C22 8.07989 22 7.51984 21.782 7.09202C21.5903 6.71569 21.2843 6.40973 20.908 6.21799C20.4802 6 19.9201 6 18.8 6H17M2 10H4M20 10H22M2 14H4M20 14H22M12 6V10M10 8H14M17 21V6.2C17 5.0799 17 4.51984 16.782 4.09202C16.5903 3.71569 16.2843 3.40973 15.908 3.21799C15.4802 3 14.9201 3 13.8 3H10.2C9.07989 3 8.51984 3 8.09202 3.21799C7.71569 3.40973 7.40973 3.71569 7.21799 4.09202C7 4.51984 7 5.0799 7 6.2V21H17ZM14 21V17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17V21H14Z"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h3 className="mt-8 text-lg font-semibold text-black group-hover:text-white ">
                    Healthcare Hero
                  </h3>
                  <p className="mt-4 text-base text-gray-600 group-hover:text-white">
                    Jumped{" "}
                    <span className="text-green-500 drop-shadow-md font-bold">
                      +67%
                    </span>{" "}
                    after our tip. Healthcare's robust growth spotlighted.
                  </p>
                </div>
              </a>
              <a
                href="/login?next=top-stocks"
                className="transition-all  duration-500 bg-white hover:bg-indigo-500  hover:shadow-xl m-2 p-4 relative z-40 group  "
              >
                <div className=" absolute  bg-indigo-500/50 top-0 left-0 w-24 h-1 z-30  transition-all duration-200   group-hover:bg-white group-hover:w-1/2  "></div>
                <div className="py-2 px-9 relative  ">
                  <svg
                    className="w-16 h-16 fill-gray-400 group-hover:fill-white"
                    version="1.1"
                    id="Capa_1"
                    viewBox="0 0 15.289 15.289"
                  >
                    <g>
                      <path
                        d="M14.968,3.978c-0.22-0.051-0.439,0.088-0.49,0.308c-0.002,0.006-0.038,0.146-0.129,0.352
		c-0.058-0.014-0.117-0.021-0.179-0.021c-0.091,0-0.179,0.019-0.261,0.047l-3.388-3.386c0.009-0.046,0.016-0.093,0.016-0.141
		c0-0.049-0.007-0.096-0.016-0.143c0.26-0.125,0.448-0.177,0.481-0.186c0.22-0.05,0.355-0.269,0.307-0.49
		c-0.05-0.22-0.27-0.358-0.49-0.308c-0.012,0.003-0.371,0.092-0.851,0.365C9.894,0.353,9.817,0.337,9.736,0.337
		c-0.44,0-0.8,0.358-0.8,0.799c0,0.011,0.003,0.02,0.003,0.03C8.488,1.625,8.052,2.256,7.76,3.113
		C7.509,3.248,7.336,3.51,7.336,3.815c0,0.172,0.056,0.33,0.147,0.46C7.374,5.012,7.358,5.87,7.484,6.873l0.097,0.831
		c0.085,0.924,0.045,1.733-0.067,2.448c-0.052,0.009-0.1,0.021-0.147,0.038L5.089,7.913C5.107,7.866,5.118,7.819,5.127,7.77
		c0.507-0.075,1.21-0.114,1.771-0.109V6.843c-0.75-0.008-1.426,0.068-2.045,0.19C4.714,6.917,4.537,6.844,4.34,6.844
		c-0.348,0-0.641,0.225-0.751,0.535C2.876,7.632,2.273,7.947,1.784,8.271C1.753,8.267,1.721,8.262,1.689,8.262
		c-0.407,0-0.739,0.304-0.79,0.695c-0.537,0.485-0.811,0.876-0.84,0.925c-0.098,0.165-0.067,0.368,0.062,0.498
		c0.023,0.022,0.05,0.044,0.079,0.063c0.194,0.115,0.446,0.053,0.563-0.14c0.021-0.035,0.174-0.272,0.469-0.587
		c0.083,0.059,0.177,0.1,0.279,0.123l4.04,4.039c0,0.063,0.009,0.123,0.022,0.18c-0.313,0.295-0.55,0.447-0.584,0.469
		c-0.194,0.116-0.258,0.367-0.143,0.562c0.018,0.03,0.039,0.057,0.063,0.08c0.13,0.13,0.335,0.16,0.499,0.062
		c0.043-0.023,0.344-0.214,0.737-0.587c0.065,0.018,0.133,0.03,0.204,0.03c0.442,0,0.8-0.358,0.8-0.8
		c0-0.118-0.027-0.229-0.072-0.331c0.341-0.501,0.667-1.126,0.919-1.886c0.265-0.13,0.449-0.399,0.449-0.716
		c0-0.171-0.055-0.329-0.147-0.459c0.141-0.781,0.195-1.67,0.116-2.679c0,0-0.055-0.832-0.09-0.836
		C8.199,6.095,8.187,5.348,8.251,4.702l2.334,2.333C10.119,7.082,9.259,7.09,8.679,7.043l0.389,0.841
		c0.72,0.027,1.352-0.03,1.922-0.138c0.135,0.103,0.302,0.166,0.483,0.166c0.34,0,0.629-0.212,0.745-0.51
		c0.808-0.32,1.418-0.754,1.873-1.194c0.027,0.002,0.054,0.008,0.081,0.008c0.44,0,0.799-0.358,0.799-0.799
		c0-0.087-0.016-0.169-0.042-0.248c0.246-0.401,0.347-0.69,0.35-0.701C15.327,4.248,15.189,4.028,14.968,3.978z M6.464,13.084
		l-3.981-3.98c0.001-0.015,0.004-0.028,0.004-0.043c0-0.065-0.01-0.128-0.024-0.188c0.359-0.241,0.792-0.477,1.302-0.675
		C3.911,8.349,4.114,8.444,4.34,8.444c0.008,0,0.016-0.003,0.023-0.003l2.481,2.483c0,0.005-0.001,0.011-0.001,0.016
		c0,0.336,0.207,0.622,0.5,0.741C7.126,12.252,6.866,12.73,6.6,13.117C6.557,13.103,6.511,13.091,6.464,13.084z M8.922,3.666
		C8.899,3.542,8.845,3.43,8.771,3.334c0.208-0.601,0.492-1.063,0.796-1.416c0.055,0.011,0.112,0.019,0.17,0.019
		c0.06,0,0.117-0.007,0.172-0.02l3.465,3.465c0,0.011-0.004,0.022-0.004,0.034c0,0.205,0.08,0.391,0.208,0.532
		c-0.354,0.301-0.813,0.582-1.404,0.79c-0.118-0.221-0.336-0.377-0.593-0.414L8.922,3.666z"
                      />
                    </g>
                  </svg>
                  <h3 className="mt-8 text-lg font-semibold text-black group-hover:text-white ">
                    BioTech Renegade
                  </h3>
                  <p className="mt-4 text-base text-gray-600 group-hover:text-white">
                    Gained a swift{" "}
                    <span className="text-green-500 drop-shadow-md font-bold">
                      +48%
                    </span>{" "}
                    on market insights. BioTech revamp reaps rewards.
                  </p>
                </div>
              </a>
            </div>
          </div>

          <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0 justify-center md:justify-end">
            <a
              href="/login?next=top-stocks"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Stock Analysis <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
