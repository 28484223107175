import { useState } from "react";

import ContactUsDropdown from "./ContactUsDropdown";
import { BuildingOffice2Icon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useCSRFToken } from "./CSRFTokenContext";
import PhoneInput from "react-phone-number-input";

const reasons = [
  { id: 1, name: "Need help with account" },
  { id: 2, name: "I'm a company wanting a featured article" },
  { id: 3, name: "I want to become an ambassador" },
  { id: 4, name: "Legal Contact" },
];

const reasonsForPublicCompanies = [
  { id: 1, name: "1-2 weeks" },
  { id: 2, name: "2-4 weeks" },
  { id: 3, name: "1-2 months" },
  { id: 4, name: "2-6 months" },
];

export default function ContactUs({ isPublicCompaniesContactForm = false }) {
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    reason: reasons[0].id,
    message: "",
    companyTicker: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { csrfToken } = useCSRFToken();

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handlePhoneChange(value) {
    setPhone(value);

    setFormData((prevState) => ({
      ...prevState,
      phoneNumber: value,
    }));
  }

  function handleReasonChange(selectedReason) {
    setFormData((prevState) => ({
      ...prevState,
      reason: selectedReason.id,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    let reasonObject;

    if (isPublicCompaniesContactForm) {
      reasonObject = reasonsForPublicCompanies.find(
        (reason) => reason.id === formData.reason
      );
    } else {
      reasonObject = reasons.find((reason) => reason.id === formData.reason);
    }

    const formDataWithName = {
      ...formData,
      reason: reasonObject ? reasonObject.name : "",
    };

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify(formDataWithName),
      });

      if (response.status == 429) {
        return setErrorMessage(
          `Too many attempted tries. Please wait and try again in a few minutes.`
        );
      }

      const data = await response.json();
      if (response.ok) {
        setSuccessMessage(
          data.message || "Your message has been sent successfully!"
        );
      } else {
        if (data.errors) {
          const errors = data.errors.map((err) => err.msg).join(", ");
          setErrorMessage(`Failed to signup. ${errors}`);
        } else {
          console.error(data.errors);
          setErrorMessage(
            data.message || "Failed to submit form. Please try again."
          );
        }
      }
    } catch (error) {
      console.error("Failed to submit contact form:", error);
      setErrorMessage("Failed to submit form. Please try again.");
    }
  }

  let form = (
    <form
      onSubmit={handleSubmit}
      method="POST"
      className={
        isPublicCompaniesContactForm
          ? "mx-auto mt-16 max-w-xl"
          : "px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
      }
    >
      <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label
              placeholder="Bob"
              htmlFor="first-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              First name
            </label>
            <div className="mt-2.5">
              <input
                required
                onChange={handleChange}
                placeholder="Han"
                type="text"
                name="firstName"
                id="firstName"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Last name
            </label>
            <div className="mt-2.5">
              <input
                required
                onChange={handleChange}
                placeholder="Solo"
                type="text"
                name="lastName"
                id="lastName"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          {isPublicCompaniesContactForm ? (
            <div className="sm:col-span-2">
              <label
                htmlFor="company-ticker"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Company Name / Ticker
              </label>
              <input
                onChange={handleChange}
                required
                type="text"
                name="companyTicker"
                placeholder="Skywalker Ltd / SKYW"
                id="companyTicker"
                className="mt-2.5 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          ) : null}
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                required
                onChange={handleChange}
                placeholder="hansolo@gmail.com"
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="phone-number"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Phone number
            </label>
            <div className="mt-2.5">
              <PhoneInput
                international
                defaultCountry="US"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Enter phone number"
                className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          {isPublicCompaniesContactForm ? (
            <ContactUsDropdown
              reasons={reasonsForPublicCompanies}
              selectedReason={reasonsForPublicCompanies.find(
                (reason) => reason.id === formData.reason
              )}
              onReasonChange={handleReasonChange}
              listboxLabel={"Timeline for campaign"}
            />
          ) : (
            <ContactUsDropdown
              reasons={reasons}
              selectedReason={reasons.find(
                (reason) => reason.id === formData.reason
              )}
              onReasonChange={handleReasonChange}
              listboxLabel={"Reason for contacting us"}
            />
          )}
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                required
                onChange={handleChange}
                placeholder="Never tell me the odds"
                name="message"
                id="message"
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={""}
              />
            </div>
          </div>
          {successMessage && (
            <div className="w-full sm:col-span-2 text-green-500">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="w-full sm:col-span-2 text-red-500">
              {errorMessage}
            </div>
          )}
        </div>
        <div className="mt-8 flex justify-end">
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {isPublicCompaniesContactForm ? "Let's talk" : "Send message"}
          </button>
        </div>
      </div>
    </form>
  );

  if (isPublicCompaniesContactForm) {
    return (
      <div className="isolate bg-white px-6 py-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Contact Us
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Connect with Equity Trader X to kickstart your tailored digital
            marketing journey. Share your details, and let's drive your
            company's success together.
          </p>
        </div>
        {form}
      </div>
    );
  } else {
    return (
      <div className="relative isolate bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                <svg
                  className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M130 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="white"
                  />
                  <svg
                    x="100%"
                    y={-1}
                    className="overflow-visible fill-gray-50"
                  >
                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  />
                </svg>
              </div>
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                Get in touch
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Have questions or need support? Submit your ticket here and one
                of our representatives will reach out shortly.
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <BuildingOffice2Icon
                      className="h-7 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    930 S 4th St, Ste 209 #4088
                    <br />
                    Las Vegas, NV 89101
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon
                      className="h-7 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    <a
                      className="hover:text-gray-900"
                      href="mailto:info@equitytraderx.com"
                    >
                      info@equitytraderx.com
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {form}
        </div>
      </div>
    );
  }
}
