import React, { useState, useEffect } from "react";
import ReactHoverVideoPlayer from "react-hover-video-player";
import { client } from "./../components/ContentfulClient";
import Loading from "./../components/Loading";
import { formatDate } from "./../components/utils";

export default function DailyDebriefings() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    client
      .getEntries({ content_type: "dailyDebriefings" })
      .then((response) => {
        const transformedPosts = response.items.sort((a, b) => {
          return new Date(b.fields.postDate) - new Date(a.fields.postDate);
        });

        setPosts(transformedPosts);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Daily Debriefings
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Stay ahead with our Daily Debriefings, offering concise and
            insightful summaries from the forefront of industry developments.
          </p>
        </div>
        {isLoading ? (
          <Loading center={true} />
        ) : (
          <div className="mx-auto mt-8 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 xl:grid-cols-3 md:grid-cols-2">
            {posts.map((post) => (
              <article
                key={post.sys.id}
                className="relative isolate flex flex-col justify-end items-center overflow-hidden rounded-2xl bg-gray-900 hover:opacity-90"
              >
                <a href={`/daily-debriefings/${post.sys.id}`}>
                  <ReactHoverVideoPlayer
                    videoSrc={post.fields.debriefing.fields.file.url}
                    pausedOverlay={
                      <div className="">
                        <img
                          src={post.fields.featuredImage.fields.file.url}
                          alt=""
                          className="absolute inset-0 h-full w-full object-cover"
                        />
                        <div className="absolute inset-0 z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                        <div className="absolute inset-0 z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                        <div className="absolute bottom-0 p-6 pb-4 w-full z-10">
                          <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                            <span className="mr-2 font-bold">
                              Daily Debriefing
                            </span>
                            <time
                              dateTime={post.fields.postDate}
                              className="mr-8"
                            >
                              {formatDate(post.fields.postDate)}
                            </time>
                          </div>
                          <h3 className="text-lg font-semibold leading-6 text-white">
                            <a href={`/daily-debriefings/${post.sys.id}`}>
                              <span className="absolute inset-0" />
                              {post.fields.title}
                            </a>
                          </h3>
                        </div>
                      </div>
                    }
                    loadingOverlay={<div className="loading-spinner-overlay" />}
                    hoverOverlay={<div className="hover-video-overlay" />}
                    restartOnPaused={false}
                    loop={true}
                    playbackRangeStart={0}
                    playbackRangeEnd={20}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    muted={false}
                  />
                </a>
              </article>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
