import WidgetBot from "@widgetbot/react-embed";

export default function ChatRoom() {
  return (
    <div style={{ backgroundColor: "#141721" }}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center mb-8">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Chat Room
          </h2>
          <p className="mt-2 text-lg leading-8 text-white">
            Connect, collaborate, and prosper with our trader community chat
            room.
          </p>
        </div>
        <WidgetBot
          width="100%"
          height="1000px"
          server="1185463156504539186"
          channel="1185469877528571934"
          shard="https://emerald.widgetbot.io"
        />
      </div>
    </div>
  );
}
