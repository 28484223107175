import React, { useState } from "react";
import { useCSRFToken } from "./CSRFTokenContext";

export default function ForgotPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { csrfToken, fetchCsrfToken } = useCSRFToken();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic client-side validation for matching passwords
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await fetch("/api/forgot-password/confirm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          password: password,
          token: token,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setLoading(false);
        setSuccessMessage("Successfully reset password! You may login now.");
      } else {
        setLoading(false);
        setErrorMessage(
          data.message ||
            "An error occurred. Please try again or contact support."
        );
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        "Technical difficulties, please try again later or contact support."
      );
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/logo/black_2_smaller.png"
            alt="Equity Trader X"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Confirm Password Reset
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Enter your new password below.
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="new-password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <input
                id="new-password"
                name="new_password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <input
                id="confirm-password"
                name="confirm_password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>
            {errorMessage && (
              <div className="text-red-500 text-sm drop-shadow-lg">
                {errorMessage}
              </div>
            )}
            {loading ? (
              <div>Loading...</div>
            ) : (
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Reset Password
              </button>
            )}
          </form>
          {successMessage && (
            <div className="text-green-500 text-sm mt-3 drop-shadow-lg">
              {successMessage}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
