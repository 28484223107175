import React, { useEffect, useState } from "react";
import Loading from "./../components/Loading";
import { useUser } from "./../UserContext";
import { formatDateReadable, formatDate } from "./../components/utils";
import { client } from "./../components/ContentfulClient";
import NotPremiumPageMini from "./NotPremiumPageMini";
import { getTitleToSlug } from "./../components/utils.js";

export default function Dashboard() {
  const { state } = useUser();
  const [announcements, setAnnouncements] = useState([]);
  const [isLoadingAnnouncements, setIsLoadingAnnouncements] = useState(true);
  const [webinarPosts, setWebinarPosts] = useState([]);
  const [isLoadingWebinars, setIsLoadingWebinars] = useState(true);
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoadingBlogPosts, setIsLoadingBlogPosts] = useState(true);

  const user = state.user;
  let activeSubscription = user.subscriptionStatus === "active";

  useEffect(() => {
    client
      .getEntries({ content_type: "blogPost-2" })
      .then((response) => {
        const transformedPosts = response.items.sort(
          (a, b) => new Date(b.datetime) - new Date(a.datetime)
        );

        const firstTwoItems = transformedPosts.slice(0, 2);

        setBlogPosts(firstTwoItems);
        setIsLoadingBlogPosts(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .getEntries({ content_type: "webinars" })
      .then((response) => {
        const transformedPosts = response.items.sort((a, b) => {
          return new Date(b.fields.postDate) - new Date(a.fields.postDate);
        });

        const firstTwoItems = transformedPosts.slice(0, 2);

        setWebinarPosts(firstTwoItems);
        setIsLoadingWebinars(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const response = await fetch("/api/discord/announcements");
      if (!response.ok) {
        console.error("Failed to fetch announcements");
        return;
      }
      const data = await response.json();
      let latestFirst = data.reverse();

      setAnnouncements(latestFirst);
      setIsLoadingAnnouncements(false);
    };

    if (user) {
      fetchAnnouncements();
    }
  }, [user]);

  const urlRegex =
    /(?:https?:\/\/[\w-]+(?:\.[\w-]+)+(?:[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?|www[\w-]+(?:\.[\w-]+)+(?:[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/g;

  const styledLinksForContent = (content) =>
    content.replace(
      urlRegex,
      '<a href="$&" class="text-indigo-400 hover:text-indigo-500 underline" target="_blank">$&</a>'
    );

  return (
    <main className="pt-20 md:pt-8 pb-8 md:pb-2 px-4 md:px-10 flex-1 bg-gray-100 rounded-md transition duration-500 ease-in-out overflow-y-auto h-screen">
      <div className="flex flex-col text-3xl">
        <span className="font-semibold">Hello there,</span>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div
          className={`mr-6 w-full lg:w-1/2 mt-8 py-4 flex-shrink-0 flex flex-col rounded-md ${
            activeSubscription ? "bg-white" : "bg-gray-900"
          }`}
        >
          {/* <!-- Card list container --> */}

          <a
            href="/webinars"
            className={`flex items-center pt-1 pb-1 px-8 text-lg font-semibold
					capitalize  hover:text-indigo-600 cursor-pointer hover:underline ${
            activeSubscription ? "text-black" : "text-white"
          }`}
          >
            {/* <!-- Header --> */}
            {activeSubscription ? (
              <span>latest webinars</span>
            ) : (
              <span>latest webinars & blog posts</span>
            )}
            <button className="ml-2">
              <svg className="h-5 w-5 fill-current" viewBox="0 0 256 512">
                <path
                  d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                ></path>
              </svg>
            </button>
          </a>

          <div>
            {/* <!-- List --> */}

            {!activeSubscription ? (
              <NotPremiumPageMini />
            ) : (
              <div>
                <ul className="pt-1 pb-2 px-3">
                  {isLoadingWebinars ? <Loading /> : null}
                  {webinarPosts.map((webinar, index) => (
                    <li key={index} className="mt-2 ts-card">
                      <a
                        className="p-5 flex flex-col justify-between
								bg-gray-100 rounded-md"
                        href={`/webinars/${webinar.sys.id}`}
                      >
                        <div
                          className="flex items-center justify-between
									font-semibold capitalize"
                        >
                          <span className="w-9/12">{webinar.fields.title}</span>
                          <span className="w-3/12 text-sm font-medium leading-snug text-gray-600 text-end">
                            {formatDate(webinar.fields.postDate)}
                          </span>
                        </div>

                        <p
                          className="text-sm font-medium leading-snug
									text-gray-600 my-3"
                        >
                          {webinar.fields.shortDescription}
                        </p>

                        <div className="flex justify-center items-end">
                          <div className="w-2/3">
                            <img
                              alt=""
                              className="w-full rounded-md"
                              src={webinar.fields.featuredImage.fields.file.url}
                            />
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>

                <a
                  href="/webinars"
                  className="flex justify-center capitalize text-indigo-600 hover:text-indigo-700 hover:underline"
                >
                  <span>
                    see all <span aria-hidden="true">→</span>
                  </span>
                </a>
              </div>
            )}
          </div>

          {!activeSubscription ? null : (
            <a
              href="/blog"
              className="flex items-center pt-4 md:pt-1 pb-1 px-8 text-lg font-semibold
					capitalize hover:text-indigo-600 cursor-pointer hover:underline"
            >
              {/* <!-- Header --> */}
              <span>latest blog posts</span>
              <button className="ml-2">
                <svg className="h-5 w-5 fill-current" viewBox="0 0 256 512">
                  <path
                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                  ></path>
                </svg>
              </button>
            </a>
          )}

          <div>
            {!activeSubscription ? null : (
              <div>
                <ul className="pt-1 pb-2 px-3">
                  {isLoadingBlogPosts ? <Loading /> : null}
                  {blogPosts.map((blogPost, index) => (
                    <li key={index} className="ts-card mt-2 cursor-pointer">
                      <a
                        className="p-5 flex flex-col justify-between
								bg-gray-100 rounded-md"
                        href={`/blog/posts/${blogPost.sys.id}/${getTitleToSlug(
                          blogPost.fields.title
                        )}`}
                      >
                        <div
                          className="flex items-center justify-between
									font-semibold capitalize "
                        >
                          <span>{blogPost.fields.title}</span>
                        </div>

                        <p
                          className="text-sm font-medium leading-snug
									text-gray-600 my-3"
                        >
                          {blogPost.fields.shortDescription}
                        </p>

                        <div className="flex justify-center items-end mb-4">
                          <div className="w-2/3">
                            <img
                              alt=""
                              className="w-full rounded-md"
                              src={
                                blogPost.fields.featuredImage.fields.file.url
                              }
                            />
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <div className="flex">
                            <img
                              className="h-6 w-6 rounded-full mr-3"
                              src={
                                blogPost.fields.blogPostAuthor.fields.avatar
                                  .fields.file.url
                              }
                              alt=""
                            />
                            <span>
                              <span
                                className="text-indigo-500
												font-semibold"
                              >
                                {blogPost.fields.blogPostAuthor.fields.name}
                              </span>
                            </span>
                          </div>

                          <p
                            className="text-sm font-medium leading-snug
										text-gray-600"
                          >
                            {formatDate(blogPost.fields.postDate)}
                          </p>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>

                <a
                  href="/blog"
                  className="flex justify-center capitalize text-indigo-600 hover:text-indigo-700 hover:underline"
                >
                  <span>
                    see all <span aria-hidden="true">→</span>
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>

        <div
          className="mr-6 w-full lg:w-1/2 mt-8 py-4 flex-shrink-0 flex flex-col rounded-md text-white"
          style={{ backgroundColor: "#303339" }}
        >
          <a
            href="/chat-room"
            className="flex items-center pt-1 pb-8 px-8 text-lg font-bold
					capitalize hover:text-indigo-400 cursor-pointer"
          >
            {/* <!-- Header --> */}
            <span>latest announcements</span>
            <button className="ml-2">
              <svg className="h-5 w-5 fill-current" viewBox="0 0 256 512">
                <path
                  d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                ></path>
              </svg>
            </button>
          </a>

          {isLoadingAnnouncements ? <Loading /> : null}
          <ul className="px-8 space-y-8">
            {announcements.map((announcement, index) => (
              <li key={index} className="text-white">
                <div className="flex">
                  <img
                    className="rounded-full w-auto h-12"
                    alt="discord avatar"
                    src={`https://cdn.discordapp.com/avatars/${announcement.author.id}/${announcement.author.avatar}.png`}
                  />
                  <div className="flex flex-col pl-2">
                    <div className="flex">
                      <span className="capitalize text-white text-sm font-semibold">
                        {announcement.author.username}
                      </span>
                      <span className="pl-2 text-xs pt-0.5 text-gray-400">
                        {formatDateReadable(announcement.timestamp)}
                      </span>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: styledLinksForContent(announcement.content),
                      }}
                    ></p>
                    {announcement.attachments &&
                      announcement.attachments.length > 0 && (
                        <div className="mt-3 grid grid-cols-3 gap-x-4 gap-y-4">
                          {announcement.attachments.map(
                            (attachment, attachmentIndex) => (
                              <img
                                key={attachmentIndex}
                                src={attachment.url}
                                className="text-indigo-600 hover:text-indigo-700"
                                alt="Discord announcement"
                              />
                            )
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
}
