import { CheckCircleIcon } from "@heroicons/react/20/solid";

const benefits = [
  "Access to Expert-Driven Webinars",
  "Access to the Blog",
  "Daily Debriefings (First Access to Top Stocks)",
  "Full Discord Community Access",
];

export default function NotPremiumPage() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32 h-screen lg:pl-72">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <div className="relative h-96 w-full rounded-2xl shadow-xl lg:h-auto lg:max-w-sm">
              <img
                className="h-96 w-full flex-none rounded-2xl object-cover lg:aspect-square lg:h-auto"
                src="/subscribe-photo.jpg"
                alt="financial screenshot"
              />
              <div className="absolute inset-0 bg-indigo-600 rounded-2xl opacity-40"></div>{" "}
            </div>
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Subscribe For Full Access
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Join a community where the market's latest moves are at your
                fingertips. Subscribing means accessing premium insights,
                detailed analyses, and expert-driven webinars that can transform
                your trading strategy. Don't just follow the market—get ahead of
                it.
              </p>
              <ul
                role="list"
                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon
                      className="h-7 w-5 flex-none"
                      aria-hidden="true"
                    />
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="mt-10 flex">
                <a
                  href="/subscribe"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Subscribe Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
            style={{
              clipPath:
                "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
