import { useEffect } from "react";
import { useUser } from "./../UserContext";
import Loading from "./../components/Loading";
import React, { useState } from "react";

export default function Success() {
  const { state, dispatch } = useUser();
  const [validSession, setValidSession] = useState(false);

  useEffect(() => {
    async function verifySession() {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionId = queryParams.get("session_id");
      if (sessionId) {
        const response = await fetch(`/verify-session?session_id=${sessionId}`);
        const data = await response.json();
        if (!data.valid) {
          alert("Invalid session ID. Please contact support.");
          window.location.href = "/";
          return;
        } else {
          setValidSession(true);
        }

        if (data.user) {
          dispatch({ type: "SET_USER", payload: data.user });
        }
      } else {
        window.location.href = "/";
      }
    }

    verifySession();
  }, [dispatch]);

  if (state.loading || !validSession) {
    return <Loading />;
  } else {
    return (
      <div className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="bg-white p-6 rounded-md">
          <svg
            viewBox="0 0 24 24"
            className="text-green-600 w-16 h-16 mx-auto my-6"
          >
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            ></path>
          </svg>
          <div className="text-center">
            <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
              Payment Received!
            </h3>
            <p className="text-gray-600 my-2">
              Thank you for subscribing to ETX.
            </p>
            <p> Have a great day!</p>
            <div className="py-10 text-center">
              <a
                href="/"
                className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3 rounded-md"
              >
                GO BACK
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
