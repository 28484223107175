import { useState, useEffect } from "react";
import { toSlug, formatDate } from "./utils";
import { client } from "./ContentfulClient";

export const useBlogPosts = (filterCondition) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const categoryTitle = posts.length > 0 ? posts[0].category.title : null;
  const authorName = posts.length > 0 ? posts[0].author.name : null;
  const authorRole = posts.length > 0 ? posts[0].author.role : null;

  useEffect(() => {
    client
      .getEntries({ content_type: "blogPost-2" })
      .then((response) => {
        const transformedPosts = response.items
          .filter(filterCondition)
          .map(transformPostData)
          .sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

        setPosts(transformedPosts);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  return { posts, isLoading, categoryTitle, authorName, authorRole };
};

const transformPostData = (item) => ({
  id: item.sys.id,
  title: item.fields.title,
  imageUrl: `https:${item.fields.featuredImage.fields.file.url}`,
  date: formatDate(item.fields.postDate),
  datetime: item.fields.postDate,
  category: {
    title: item.fields.category,
    href: "/blog/categories/" + toSlug(item.fields.category),
  },
  description: item.fields.shortDescription,
  author: {
    name: item.fields.blogPostAuthor.fields.name,
    role: item.fields.blogPostAuthor.fields.role,
    imageUrl: `https:${item.fields.blogPostAuthor.fields.avatar.fields.file.url}`,
    href: "/blog/authors/" + item.fields.blogPostAuthor.fields.authorId,
  },
});
