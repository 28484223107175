import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";

export default function UnderConstruction({ backgroundColor }) {
  const bgColorClass = backgroundColor || "bg-white";

  return (
    <>
      <main
        className={`grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8 ${bgColorClass}`}
      >
        <div className="text-center">
          <div className="flex items-center justify-center">
            <WrenchScrewdriverIcon className="h-24 w-24 text-indigo-600 text-center" />
          </div>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page Under Construction
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Pardon our dust, renovations in progress...
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go back home
            </a>
            <a
              href="/contact-us"
              className="text-sm font-semibold text-gray-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
