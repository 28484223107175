import React, { createContext, useContext, useState, useEffect } from "react";

const CSRFTokenContext = createContext();

export const CSRFTokenProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState("");

  const fetchCsrfToken = async () => {
    const response = await fetch("/get-csrf-token");
    const data = await response.json();
    setCsrfToken(data.csrfToken);
  };

  useEffect(() => {
    fetchCsrfToken(); // Fetch the token when the component mounts
  }, []);

  return (
    <CSRFTokenContext.Provider
      value={{ csrfToken, setCsrfToken, fetchCsrfToken }}
    >
      {children}
    </CSRFTokenContext.Provider>
  );
};

// Custom hook to use the CSRFTokenContext
export const useCSRFToken = () => useContext(CSRFTokenContext);
