import React, { useState, useEffect } from "react";
import Loading from "./../components/Loading";
import { client } from "./../components/ContentfulClient";
import { formatDate } from "./../components/utils";
import {
  CalendarDaysIcon,
  CreditCardIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";

let sortTopStocks = (stocks) => {
  // Separate the companies by type
  let microCap = stocks
    .filter((stock) => stock.type === "micro_cap")
    .sort((a, b) => a.rank - b.rank);
  let blueChips = stocks
    .filter((stock) => stock.type === "blue_chips")
    .sort((a, b) => a.rank - b.rank);

  // Interleave the two arrays
  let sortedStocks = [];
  for (let i = 0; i < Math.max(microCap.length, blueChips.length); i++) {
    if (i < microCap.length) {
      sortedStocks.push(microCap[i]);
    }
    if (i < blueChips.length) {
      sortedStocks.push(blueChips[i]);
    }
  }

  return sortedStocks;
};

let sortTopStocksForMicroCaps = (stocks) => {
  return stocks
    .filter((stock) => stock.type === "micro_cap")
    .sort((a, b) => a.rank - b.rank);
};

let sortTopStocksForBlueChips = (stocks) => {
  return stocks
    .filter((stock) => stock.type === "blue_chips")
    .sort((a, b) => a.rank - b.rank);
};

let getLastUpdatedDate = (lastUpdatedAtArr) => {
  let lastUpdated = lastUpdatedAtArr.sort((a, b) => new Date(a) - new Date(b));

  return lastUpdated[lastUpdated.length - 1];
};

export default function Webinars() {
  const [isLoading, setIsLoading] = useState(true);
  const [sortedStocks, setSortedStocks] = useState([]);
  const [sortedStocksForBlueChips, setSortedStocksForBlueChips] = useState([]);
  const [sortedStocksForMicroCaps, setsortedStocksForMicroCaps] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(formatDate(Date.now()));

  useEffect(() => {
    client
      .getEntries({ content_type: "topStocks" })
      .then((response) => {
        let transformedData = response.items.map((topStock) => {
          return {
            id: topStock.sys.id,
            rank: topStock.fields.rank,
            ticker: topStock.fields.tickerSymbol,
            name: topStock.fields.companyName,
            type:
              topStock.fields.marketCategory.fields.marketCategory ===
              "Blue Chips"
                ? "blue_chips"
                : "micro_cap",
            ytd: topStock.fields.ytd,
            marketCap: topStock.fields.marketCap,
          };
        });

        let lastUpdatedAtArr = response.items.map((topStock) => {
          return topStock.sys.updatedAt;
        });
        let lastUpdateComputed = getLastUpdatedDate(lastUpdatedAtArr);

        const sortedStocksInc = sortTopStocks(transformedData);
        const sortedStocksForMicro = sortTopStocksForMicroCaps(transformedData);
        const sortedStocksForBlue = sortTopStocksForBlueChips(transformedData);

        setLastUpdate(formatDate(lastUpdateComputed));
        setSortedStocks(sortedStocksInc);
        setSortedStocksForBlueChips(sortedStocksForBlue);
        setsortedStocksForMicroCaps(sortedStocksForMicro);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="bg-gray-100">
      <h2 className="text-gray-400 pb-8">
        Last updated on <i className="italic">{lastUpdate}</i>
      </h2>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Top Stocks
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600 md:px-24">
            Click on the featured stock to uncover the compelling reasons behind
            its top pick status.
          </p>
        </div>
        {isLoading ? (
          <div className="pt-16">
            <Loading center={false} />
          </div>
        ) : (
          <div>
            <div className="flex flex-col items-center mt-8">
              <div className="md:grid auto-rows-fr mx-auto md:grid-cols-2 grid-cols-1 gap-y-4 gap-x-40 hidden w-full mb-4">
                <h2 className="text-center flex items-center justify-center font-bold text-2xl">
                  Micro Caps
                </h2>
                <h2 className="text-center flex items-center justify-center font-bold text-2xl">
                  Mid - Blue Chips
                </h2>
              </div>
              <div className="md:grid auto-rows-fr mx-auto md:grid-cols-2 grid-cols-1 gap-y-4 gap-x-20 hidden">
                {sortedStocks.map((company, index) => (
                  <React.Fragment key={index}>
                    <a
                      href={`/top-stocks/${company.id}`}
                      className="hover:cursor-pointer"
                    >
                      <h2 className="sr-only">Summary</h2>
                      <div className="ts-card rounded-lg bg-gray-50  shadow-sm ring-1 ring-gray-900/5">
                        <dl className="flex flex-wrap">
                          <div className="flex-auto pl-6 pt-6">
                            <dt className="text-sm font-semibold leading-6 text-indigo-600">
                              Rank:{" "}
                              <span className="text-indigo-600 font-bold">
                                {company.rank}
                              </span>
                            </dt>
                            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                              {company.name}
                            </dd>
                          </div>
                          <div className="flex-none self-end px-6 pt-4">
                            <dt className="sr-only">Status</dt>
                            {company.type == "blue_chips" ? (
                              <dd className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                Blue Chip
                              </dd>
                            ) : (
                              <dd className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                                Micro Cap
                              </dd>
                            )}
                          </div>
                          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                            <dt className="flex-none">
                              <span className="sr-only">Client</span>
                              <UserCircleIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900 uppercase">
                              {company.ticker}
                            </dd>
                          </div>
                          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                              <span className="sr-only">Due date</span>
                              <CalendarDaysIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm leading-6 text-gray-500">
                              (YTD){" "}
                              <span
                                className={`${
                                  company.ytd.startsWith("-")
                                    ? "text-red-700"
                                    : company.ytd.startsWith("+")
                                    ? "text-green-700"
                                    : ""
                                } text-shadow font-bold`}
                              >
                                {company.ytd}
                              </span>
                            </dd>
                          </div>
                          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                              <span className="sr-only">Status</span>
                              <CreditCardIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm leading-6 text-gray-500">
                              (Market Cap){" "}
                              <span className="font-bold">
                                {company.marketCap}
                              </span>
                            </dd>
                          </div>
                        </dl>
                        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                          <span className="text-sm font-semibold leading-6 text-gray-900">
                            Read more <span aria-hidden="true">&rarr;</span>
                          </span>
                        </div>
                      </div>
                    </a>
                  </React.Fragment>
                ))}
              </div>
              <h2 className="text-center flex items-center justify-center font-bold text-2xl mb-8 show md:hidden">
                Micro Caps
              </h2>
              <div className="grid auto-rows-fr mx-auto md:grid-cols-2 grid-cols-1 gap-y-4 gap-x-20 md:hidden">
                {sortedStocksForMicroCaps.map((company, index) => (
                  <React.Fragment key={index}>
                    <a
                      href={`/top-stocks/${company.id}`}
                      className="hover:cursor-pointer"
                    >
                      <h2 className="sr-only">Summary</h2>
                      <div className="ts-card rounded-lg bg-gray-50  shadow-sm ring-1 ring-gray-900/5">
                        <dl className="flex flex-wrap">
                          <div className="flex-auto pl-6 pt-6">
                            <dt className="text-sm font-semibold leading-6 text-indigo-600">
                              Rank:{" "}
                              <span className="text-indigo-600 font-bold">
                                {company.rank}
                              </span>
                            </dt>
                            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                              {company.name}
                            </dd>
                          </div>
                          <div className="flex-none self-end px-6 pt-4">
                            <dt className="sr-only">Status</dt>
                            {company.type == "blue_chips" ? (
                              <dd className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                Blue Chip
                              </dd>
                            ) : (
                              <dd className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                                Micro Cap
                              </dd>
                            )}
                          </div>
                          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                            <dt className="flex-none">
                              <span className="sr-only">Client</span>
                              <UserCircleIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900 uppercase">
                              {company.ticker}
                            </dd>
                          </div>
                          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                              <span className="sr-only">Due date</span>
                              <CalendarDaysIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm leading-6 text-gray-500">
                              (YTD){" "}
                              <span
                                className={`${
                                  company.ytd.startsWith("-")
                                    ? "text-red-700"
                                    : company.ytd.startsWith("+")
                                    ? "text-green-700"
                                    : ""
                                } text-shadow font-bold`}
                              >
                                {company.ytd}
                              </span>
                            </dd>
                          </div>
                          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                              <span className="sr-only">Status</span>
                              <CreditCardIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm leading-6 text-gray-500">
                              (Market Cap){" "}
                              <span className="font-bold">
                                {company.marketCap}
                              </span>
                            </dd>
                          </div>
                        </dl>
                        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                          <span className="text-sm font-semibold leading-6 text-gray-900">
                            Read more <span aria-hidden="true">&rarr;</span>
                          </span>
                        </div>
                      </div>
                    </a>
                  </React.Fragment>
                ))}
              </div>
              <h2 className="text-center flex items-center justify-center font-bold text-2xl my-8 show md:hidden">
                Mid - Blue Chips
              </h2>
              <div className="grid auto-rows-fr mx-auto md:grid-cols-2 grid-cols-1 gap-y-4 gap-x-20 md:hidden">
                {sortedStocksForBlueChips.map((company, index) => (
                  <React.Fragment key={index}>
                    <a
                      href={`/top-stocks/${company.id}`}
                      className="hover:cursor-pointer"
                    >
                      <h2 className="sr-only">Summary</h2>
                      <div className="ts-card rounded-lg bg-gray-50  shadow-sm ring-1 ring-gray-900/5">
                        <dl className="flex flex-wrap">
                          <div className="flex-auto pl-6 pt-6">
                            <dt className="text-sm font-semibold leading-6 text-indigo-600">
                              Rank:{" "}
                              <span className="text-indigo-600 font-bold">
                                {company.rank}
                              </span>
                            </dt>
                            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                              {company.name}
                            </dd>
                          </div>
                          <div className="flex-none self-end px-6 pt-4">
                            <dt className="sr-only">Status</dt>
                            {company.type == "blue_chips" ? (
                              <dd className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                                Blue Chip
                              </dd>
                            ) : (
                              <dd className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                                Micro Cap
                              </dd>
                            )}
                          </div>
                          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                            <dt className="flex-none">
                              <span className="sr-only">Client</span>
                              <UserCircleIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900 uppercase">
                              {company.ticker}
                            </dd>
                          </div>
                          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                              <span className="sr-only">Due date</span>
                              <CalendarDaysIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm leading-6 text-gray-500">
                              (YTD){" "}
                              <span
                                className={`${
                                  company.ytd.startsWith("-")
                                    ? "text-red-700"
                                    : company.ytd.startsWith("+")
                                    ? "text-green-700"
                                    : ""
                                } text-shadow font-bold`}
                              >
                                {company.ytd}
                              </span>
                            </dd>
                          </div>
                          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                            <dt className="flex-none">
                              <span className="sr-only">Status</span>
                              <CreditCardIcon
                                className="h-6 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </dt>
                            <dd className="text-sm leading-6 text-gray-500">
                              (Market Cap){" "}
                              <span className="font-bold">
                                {company.marketCap}
                              </span>
                            </dd>
                          </div>
                        </dl>
                        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                          <span className="text-sm font-semibold leading-6 text-gray-900">
                            Read more <span aria-hidden="true">&rarr;</span>
                          </span>
                        </div>
                      </div>
                    </a>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
