import React, { useState, useEffect } from "react";
import { useCSRFToken } from "./CSRFTokenContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useUser } from "./UserContext";

export default function Signup() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [consent, setConsent] = useState(false);
  const [textPlatform, setTextPlatform] = useState("sms");
  // phone verification
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationStep, setVerificationStep] = useState(false);
  const [verificationErrorMessage, setVerificationErrorMessage] = useState("");
  const { state } = useUser();
  const user = state.user;

  const { csrfToken, fetchCsrfToken } = useCSRFToken();
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  let resendVerification = async () => {
    const resp = await fetch("/api/resend-sms-verification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": csrfToken,
      },
    });

    if (resp.ok) {
      await fetchCsrfToken();

      navigate("/signup?verifySMS=true");
    } else {
      if (resp.status == 429) {
        setErrorMessage(
          "Please wait before requesting SMS verification again."
        );
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      } else {
        setErrorMessage(
          "Issue with SMS verification. Try again later or contact support."
        );
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (searchParams.has("verifySMS")) {
      setVerificationStep(true);

      const codeParam = searchParams.get("code");
      if (codeParam) {
        setVerificationCode(codeParam);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (phone) {
      if (phone.startsWith("+1")) {
        setTextPlatform("sms");
      } else {
        setTextPlatform("whatsapp");
      }
    }
  }, [phone]);

  let updateTextPlatform = (textPlatform) => {
    if (phone) {
      if (phone.startsWith("+1")) {
        setTextPlatform(textPlatform);
      } else {
        setTextPlatform("whatsapp");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setLoadingSubmission(true);

    if (!verificationStep) {
      // Add validation for passwords matching or any other checks you need
      if (password !== confirmPassword) {
        setErrorMessage("Passwords don't match!");
        return;
      }

      setLoadingSubmission(true);
      const response = await fetch("/signup", {
        // Ensure this endpoint is correct
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "CSRF-Token": csrfToken,
        },
        body: `firstName=${encodeURIComponent(
          firstName
        )}&lastName=${encodeURIComponent(lastName)}&phone=${encodeURIComponent(
          phone
        )}&username=${encodeURIComponent(email)}&password=${encodeURIComponent(
          password
        )}&consent=${consent}&textPlatform=${textPlatform}`,
      });

      if (response.ok) {
        await fetchCsrfToken();

        setLoadingSubmission(false);
        setVerificationStep(true);
      } else {
        const errorData = await response.json();
        if (errorData.errors) {
          const errors = errorData.errors.map((err) => err.msg).join(", ");
          setErrorMessage(`Failed to signup. ${errors}`);
        } else {
          console.error(errorData);
          setErrorMessage(
            `Failed to signup. Please try again or contact support.`
          );
        }
        setLoadingSubmission(false);
      }
    } else {
      setLoadingSubmission(true);
      try {
        const verifyResponse = await fetch("/api/verify-sms-code", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
          body: JSON.stringify({
            verificationCode,
          }),
        });

        if (verifyResponse.ok) {
          setLoadingSubmission(false);
          window.location.href = "/";
        } else {
          const errorData = await verifyResponse.json();
          setVerificationErrorMessage(
            errorData.message || "Verification failed. Please try again."
          );
        }
      } catch (error) {
        console.error(
          "Verification failed. Please try again or contact support.",
          error
        );
        setVerificationErrorMessage(
          "Verification failed. Please try again or contact support."
        );
      }
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="/logo/black_2_smaller.png"
            alt="Equity Trader X"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {verificationStep
              ? "Verify Your Phone Number"
              : "Create an Account"}
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {verificationStep ? (
              <>
                <div>
                  <label
                    htmlFor="verification-code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Verification Code
                  </label>
                  <div className="mt-2">
                    <input
                      id="verification-code"
                      placeholder="39284"
                      name="verification-code"
                      type="text"
                      required
                      className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                  </div>
                </div>
                {verificationErrorMessage && (
                  <div className="text-sm text-red-600">
                    {verificationErrorMessage}
                  </div>
                )}

                {loadingSubmission ? (
                  <div
                    role="status"
                    className="flex items-center justify-center"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
              </>
            ) : (
              <div>
                {user && user.sms_verified === false ? (
                  <div className="rounded-md bg-indigo-50 p-4 mb-8">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon
                          className="h-5 w-5 text-indigo-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-indigo-700">
                          Resend SMS / WhatsApp verification? Click to receive a
                          new code.{" "}
                          <span
                            onClick={(_) => resendVerification()}
                            className="cursor-pointer whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-600"
                          >
                            Details
                            <span aria-hidden="true"> &rarr;</span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="flex flex-col space-y-5">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First Name
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="Han"
                        id="first-name"
                        name="first-name"
                        type="text"
                        required
                        className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last Name
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="Solo"
                        id="last-name"
                        name="last-name"
                        type="text"
                        required
                        className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone Number
                    </label>
                    <div className="mt-2">
                      <PhoneInput
                        international
                        defaultCountry="US"
                        value={phone}
                        onChange={setPhone}
                        placeholder="Enter phone number"
                        className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center border rounded-md border-gray-300 justify-center">
                    <div
                      onClick={(_) => updateTextPlatform("sms")}
                      title="Send via SMS. Only available for US/CA numbers."
                      className={`border-r-2 broder-gray-300 flex justify-center items-center w-full py-4 cursor-pointer hover:bg-gray-300 ${
                        textPlatform == "sms" ? "bg-gray-200" : ""
                      }`}
                    >
                      <img
                        className="w-16 h-auto"
                        alt="sms icon"
                        src="/sms.png"
                      />
                    </div>
                    <div
                      title="Send via WhatsApp. Available to all numbers."
                      onClick={(_) => updateTextPlatform("whatsapp")}
                      className={`flex items-center justify-center w-full py-4 cursor-pointer hover:bg-gray-300 ${
                        textPlatform == "whatsapp" ? "bg-gray-200" : ""
                      }`}
                    >
                      <img
                        className="w-16 h-auto"
                        alt="whatsapp logo"
                        src="/whatsapp.png"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="hansolo@gmail.com"
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="********"
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        required
                        className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-2">
                      <input
                        placeholder="********"
                        id="confirm-password"
                        name="confirm-password"
                        type="password"
                        autoComplete="new-password"
                        required
                        className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <fieldset>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="consent"
                          name="consent"
                          type="checkbox"
                          checked={consent}
                          onChange={(e) => setConsent(e.target.checked)}
                          className="cursor-pointer  h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="consent" className="text-gray-500">
                          {" "}
                          BY CHECKING THE BOX, I agree that Equitytraderx.com
                          may contact me at the number I entered, with offers
                          and other info, including using automated technology,
                          recorded and SMS messages, or WhatsApp. Consent is not
                          a condition of purchase. Additionally, By CHECKING THE
                          BOX I agree to the following{" "}
                          <a
                            targe="_blank"
                            className="font-medium text-black hover:text-indigo-600"
                            href="/terms-of-service"
                          >
                            TERMS OF SERVICE
                          </a>{" "}
                          and{" "}
                          <a
                            className="font-medium text-black hover:text-indigo-600"
                            href="/privacy-policy"
                            target="_blank"
                          >
                            PRIVACY POLICY
                          </a>
                        </label>
                      </div>
                    </div>
                  </fieldset>

                  {errorMessage && (
                    <div className="text-sm text-red-600">{errorMessage}</div>
                  )}

                  {successMessage && (
                    <div className="text-sm text-green-600">
                      {successMessage}
                    </div>
                  )}

                  {loadingSubmission ? (
                    <div
                      role="status"
                      className="flex items-center justify-center"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {verificationStep ? "Verify" : "Sign Up"}
              </button>
            </div>
            {verificationStep ? null : (
              <i className="text-xs block">
                By clicking "Sign up" above, you give us permission to 1. Sign
                you up for the Equitytraderx.com alerts; 2. Send free ongoing
                updates via our independent financial email newsletter; 3. Share
                carefully selected marketing offers for products and services we
                think will interest you. Additionally, you agree to our{" "}
                <a
                  className="text-indigo-600 hover:text-indigo-700"
                  href="/terms-of-service"
                  target="_blank"
                >
                  Terms of Service
                </a>{" "}
                and our{" "}
                <a
                  className="text-indigo-600 hover:text-indigo-700"
                  href="/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </i>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
