import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { client } from "./../components/ContentfulClient";
import RichTextRenderer from "./../components/RichTextRenderer";
import Loading from "./../components/Loading";
import { toSlug, formatDateReadable } from "./../components/utils";

export default function BlogPost() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    client
      .getEntry(postId)
      .then((entry) => {
        setPost(entry.fields);
        setIsLoading(false);
      })
      .catch(console.error);
  }, [postId]);

  if (isLoading) {
    return <Loading center={true} />;
  }

  return (
    <div className="mx-auto max-w-7xl">
      <a
        href={`/blog/categories/${toSlug(post.category)}`}
        className="hover:text-indigo800 hover:underline text-base font-semibold leading-7 text-indigo-600"
      >
        {post.category}
      </a>
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {post.title}
      </h1>
      <p className="py-4">
        <span className="text-gray-500">
          {formatDateReadable(post.postDate)}
        </span>
      </p>
      <div className="relative flex items-center gap-x-4 mb-4">
        <img
          src={`https:${post.blogPostAuthor.fields.avatar.fields.file.url}`}
          alt=""
          className="h-10 w-10 rounded-full bg-gray-100"
        />
        <div className="text-sm leading-6">
          <p className="font-semibold text-gray-900 hover:text-indigo-500">
            <a href={`/blog/authors/${post.blogPostAuthor.fields.authorId}`}>
              <span className="absolute inset-0" />
              {post.blogPostAuthor.fields.name}
            </a>
          </p>
          <p className="text-gray-600">{post.blogPostAuthor.fields.role}</p>
        </div>
      </div>
      <span className="flex items-center justify-center pb-6">
        <img
          className="rounded-md shadow-md"
          alt="article header image"
          src={`https:${post.featuredImage.fields.file.url}`}
        />
      </span>
      <RichTextRenderer richText={post.body} />
    </div>
  );
}
