import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  UsersIcon,
  XMarkIcon,
  VideoCameraIcon,
  RocketLaunchIcon,
  ChatBubbleLeftRightIcon,
  NewspaperIcon,
  EnvelopeIcon,
  PresentationChartLineIcon,
  ArrowRightStartOnRectangleIcon,
  LockClosedIcon,
  Cog6ToothIcon,
  MagnifyingGlassIcon,
  ChartBarIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import { useUser } from "./../UserContext"; // Adjust the path as necessary
import { useNavigate } from "react-router-dom";
import { useCSRFToken } from "./../CSRFTokenContext";
import { useLocation } from "react-router-dom";

const navigation = [
  {
    name: "Blog",
    href: "/blog",
    icon: UsersIcon,
    premiumRequired: true,
  },
  {
    name: "Webinars",
    href: "/webinars",
    icon: VideoCameraIcon,
    premiumRequired: true,
  },
  {
    name: "Search Stocks",
    href: "/search-stocks",
    icon: MagnifyingGlassIcon,
    premiumRequired: false,
  },
  {
    name: "Top Stocks",
    href: "/top-stocks",
    icon: RocketLaunchIcon,
    premiumRequired: true,
  },
  {
    name: "Screener",
    href: "/screener",
    icon: ChartBarIcon,
    premiumRequired: true,
  },
  {
    name: "Portfolio",
    href: "/portfolio",
    icon: BanknotesIcon,
    premiumRequired: true,
  },
  { name: "News", href: "/news", icon: NewspaperIcon, premiumRequired: false },
  {
    name: "Chat Room",
    href: "/chat-room",
    icon: ChatBubbleLeftRightIcon,
    premiumRequired: false,
  },
  {
    name: "Daily Debriefings",
    href: "/daily-debriefings",
    icon: PresentationChartLineIcon,
    premiumRequired: true,
  },
  {
    name: "Contact",
    href: "/contact-us",
    icon: EnvelopeIcon,
    premiumRequired: false,
  },
  {
    name: "Settings",
    href: "/settings",
    icon: Cog6ToothIcon,
    premiumRequired: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function isPathActive(href, pathname) {
  // Ensure both href and pathname start without a trailing slash
  const formattedHref = href.replace(/\/$/, "");
  const formattedPathname = pathname.replace(/\/$/, "");

  return formattedPathname.startsWith(formattedHref);
}

export default function AppNavigation() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { state, dispatch } = useUser();
  const user = state.user;
  const navigate = useNavigate();
  const { csrfToken } = useCSRFToken();
  const location = useLocation();

  const handleLogout = async () => {
    const response = await fetch("/logout", {
      method: "POST",
      headers: { "CSRF-Token": csrfToken },
    });

    if (response.ok) {
      dispatch({ type: "LOGOUT" });
      navigate("/");
    } else {
      console.error("Logout failed", await response.text());
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <a href="/">
                        <img
                          className="h-8 w-auto"
                          src="/logo/white_vector_2.svg"
                          alt="Equity Trader X"
                        />
                      </a>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    location.pathname.replace(/\/$/, "") ===
                                      item.href
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  {item.premiumRequired &&
                                  user.subscriptionStatus !== "active" ? (
                                    <LockClosedIcon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {item.name}
                                </a>
                              </li>
                            ))}
                            <li className="block sm:hidden">
                              <button
                                onClick={handleLogout}
                                className="w-full flex items-center gap-x-4 px-2 py-3 text-sm font-semibold rounded-md leading-6 text-white hover:bg-gray-800"
                              >
                                <ArrowRightStartOnRectangleIcon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                <span>Logout</span>
                              </button>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center mt-2">
              <a href="/" className="cursor-pointer">
                <img
                  className="h-10 w-auto"
                  src="/logo/white_vector_2.svg"
                  alt="Equity Trader X"
                />
              </a>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            isPathActive(item.href, location.pathname)
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          {item.premiumRequired &&
                          user.subscriptionStatus !== "active" ? (
                            <LockClosedIcon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                          ) : (
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                          )}
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <ArrowRightStartOnRectangleIcon
                      className="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    <span>Logout</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="fixed w-full top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <a
            href="/"
            className="flex-1 text-sm font-semibold leading-6 text-white"
          >
            Equity Trader X
          </a>
        </div>
      </div>
    </>
  );
}
