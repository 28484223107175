import { Screener } from "react-ts-tradingview-widgets";

export default function ScreenerApp() {
  return (
    <div style={{ backgroundColor: "#141721" }}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Screener
          </h2>
          <p className="mt-2 text-lg leading-8 text-white">
            Handy for sorting symbols both by fundamental and technical
            indicators.
          </p>
        </div>
        <div className="mx-auto mt-8 max-w-7xl">
          <Screener
            market="america"
            colorTheme="dark"
            defaultScreen="top_gainers"
            width="100%"
            height={1200}
          ></Screener>
        </div>
      </div>
    </div>
  );
}
