import Loading from "./../components/Loading";
import BlogPostGrid from "./../components/BlogPostGrid";
import { useBlogPosts } from "./../components/UseBlogPosts";

export default function Blog() {
  const filterCondition = (item) => item === item;
  const { posts, isLoading } = useBlogPosts(filterCondition);

  return isLoading ? (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            From the blog
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Boost your financial savvy with our expert insights and practical
            tips.
          </p>
        </div>
        <div className="pt-8">
          <Loading center={false} />
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            From the blog
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Boost your financial savvy with our expert insights and practical
            tips.
          </p>
        </div>
        <BlogPostGrid posts={posts} />
      </div>
    </div>
  );
}
